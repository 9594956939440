import { AppBar, Toolbar, Menu, MenuItem, Typography, Divider, Link } from '@material-ui/core';
import Logo from './Logo';
import Feijoa from './Feijoa';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import { isLiteVersion } from './../utility/CommonUtiliy';
import { HOME, MANAGE_IDEAS } from './../constants/routes';

const { REACT_APP_VERSION, REACT_APP_NAME, REACT_APP_BUILD_TIME } = process.env;

const useStyles = makeStyles((theme) => ({
    logo: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
    },
    feijoa: {
        position: "absolute",
        left: "0%",
        top: "50%",
        transform: "translate(-50%, -50%)"
    },
    icon: {
        position: "absolute",
        right: "0%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        color: 'black'
    },
    company: {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '8px',
        fontSize: '12px'
    },
    appbar: {
        backgroundColor: '#fff',
        minHeight: '60px'
    },
    home: {
        color: '#3f51b5'
    }
}));

const Header = () => {
    const classes = useStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <AppBar position="static" className={classes.appbar}>
            <Toolbar>
                <Link href="https://www.feijoa8.com" rel="noreferrer noopener" target="_blank">
                    <Feijoa className={classes.feijoa} />
                </Link>
                <RouterLink to={HOME} className={classes.logo}>
                    <Logo />
                </RouterLink>
                <IconButton onClick={handleClick} className={classes.icon} aria-label="display more actions" edge="end" color="inherit">
                    <MoreIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >

                    <MenuItem onClick={handleClose}>
                        <Typography className={classes.home} onClick={() => history.push(HOME)}>Home</Typography>
                    </MenuItem>
                    {isLiteVersion() &&
                        <MenuItem onClick={handleClose}>
                            <Typography className={classes.home} onClick={() => history.push(MANAGE_IDEAS)}>Manage Ideas</Typography>
                        </MenuItem>
                    }
                    <MenuItem onClick={handleClose}>
                        <Link href="https://www.feijoa8.com/ceci" rel="noreferrer noopener" target="_blank">
                            About {REACT_APP_NAME}</Link>
                    </MenuItem>

                    <MenuItem onClick={handleClose}>
                        <Link href="https://www.feijoa8.com/ceci/examples" rel="noreferrer noopener" target="_blank">
                            Examples
                            </Link>
                    </MenuItem>

                    <MenuItem onClick={handleClose}>
                        <Link href="https://www.feijoa8.com/ceci/faq" rel="noreferrer noopener" target="_blank">
                            FAQ
                            </Link>
                    </MenuItem>

                    <MenuItem onClick={handleClose}>
                        <Link href="https://www.feijoa8.com/terms" rel="noreferrer noopener" target="_blank">
                            Terms & Conditions</Link></MenuItem>

                    <MenuItem onClick={handleClose}>
                        <Link href="https://www.feijoa8.com/terms#privacy" rel="noreferrer noopener" target="_blank">
                            Privacy</Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <Link href="https://www.feijoa8.com/feedback" rel="noreferrer noopener" target="_blank">
                            Feedback</Link>
                    </MenuItem>
                    <Divider />
                    <Typography align="center" component="div" className={classes.company}>{'© '}
                        {new Date().getFullYear()}
                        {' Feijoa8 Limited. '}
                        <br />
                        {'Version'} {REACT_APP_VERSION}{'.'}{REACT_APP_BUILD_TIME} </Typography>
                </Menu>

            </Toolbar>
        </AppBar>
    );
};

export default Header;