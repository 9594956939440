import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    title: {
        fontSize: 24,
        textAlign: 'center',
    },
    titleContainer: {
        flexDirection: 'row',
        marginTop: 20,
    },
    description: {
        fontSize: 14,
        textAlign: 'center',
        marginBottom: 20
    },
});

const IdeaDetails = ({ title, description }) => (
    <React.Fragment>
        <View style={styles.titleContainer}>
            <Text style={styles.title}>{title}</Text>
        </View>
        <View>
            <Text style={styles.description}>{description}</Text>
        </View>
    </React.Fragment>
);

export default IdeaDetails