import React from 'react';
import { Grid, Select, TextField, MenuItem, Button, IconButton, Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import SaveIcon from '@material-ui/icons/Save';
import { solution, CURRENCIES } from './../../../constants/solution';
import { getCurrentProjectById } from '../../../reducers/utility';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../actions/projectAction';
import { bindActionCreators } from 'redux';
import idx from 'idx';

const useStyles = makeStyles((theme) => ({
    textWithAddIcon: {
        width: '85%',
    },
    button: {
        padding: '10px',
    },
    removeIcon: {
        padding: '5px',
        marginTop: '10px',
        '& svg': {
            fontSize: 25,
            color: 'red',
            padding: '0px'
        }
    },
}));

function CreateUpdateSolutionDialog({ data, render, onSave, categoryValue, deliveryOptions, actions }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const defaultTitle = idx(data, _ => _.title) || '';
    const defaultDescription = idx(data, _ => _.description) || '';
    const defaultCost = idx(data, _ => _.cost) || '';
    const defaultCurrency = idx(data, _ => _.currency) || '$';
    const defaultTime = idx(data, _ => _.timeFrame) || '';
    const defaultTimeFrameUnit = idx(data, _ => _.timeFrameUnit) || solution[categoryValue].timeFrameUnits[0].value;
    const defaultRisks = idx(data, _ => _.risks) || [];
    const defaultAdditionalInfo = idx(data, _ => _.additionalInfo) || '';

    const [title, setTitle] = React.useState(defaultTitle);
    const [description, setDescription] = React.useState(defaultDescription);
    const [cost, setCost] = React.useState(defaultCost);
    const [currency, setCurrency] = React.useState(defaultCurrency);
    const [timeFrame, setTime] = React.useState(defaultTime);
    const [timeFrameUnit, setTimeFrameUnit] = React.useState(defaultTimeFrameUnit);
    const [risks, setRisks] = React.useState(defaultRisks);
    const [additionalInfo, setAdditionalInfo] = React.useState(defaultAdditionalInfo);
    const risksLimit = solution[categoryValue].limits.risks;

    const handleSave = () => {
        const id = data ? data.id : '';
        data ? actions.updateSolution({
            title, description, currency, cost, timeFrame,
            timeFrameUnit, risks, additionalInfo, id
        }) :
            actions.addSolution({
                title, description, currency, cost, timeFrame,
                timeFrameUnit, risks, additionalInfo
            });
        onSave && onSave();
        handleClose();
    };

    const isDuplicateOption = () => {
        if (deliveryOptions === undefined || deliveryOptions.length === 0) {
            return false;
        }
        return deliveryOptions.some(option => option.title.toUpperCase() === title.toUpperCase()) && title !== defaultTitle;
    }

    const handleTimeFrameChange = (event) => {
        setTimeFrameUnit(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
        setTitle(defaultTitle);
        setDescription(defaultDescription);
        setTime(defaultTime);
        setCost(defaultCost);
        setCurrency(defaultCurrency);
        setTimeFrameUnit(defaultTimeFrameUnit);
        setRisks(defaultRisks);
        setAdditionalInfo(defaultAdditionalInfo);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <React.Fragment>
            {render(handleClickOpen)}
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} scroll="paper">
                <DialogTitle>
                    <Typography component="div" align="center">
                        <strong>{data ? "Edit" : "Add"} Option Details</strong>
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={true} ref={descriptionElementRef}
                    tabIndex={-1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="solutionTitle"
                                name="solutionTitle"
                                label="Option title"
                                fullWidth
                                error={isDuplicateOption()}
                                variant="outlined"
                                placeholder="Option title"
                                autoFocus
                                helperText={`Total characters ${title.length}/${solution[categoryValue].limits.titleLength}`}
                                inputProps={{ maxLength: solution[categoryValue].limits.titleLength }}
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="solutionDescription"
                                name="solutionDescription"
                                label="Option Description"
                                variant="outlined"
                                placeholder="Option description"
                                multiline
                                rows={3}
                                value={description}
                                fullWidth
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                type="number"
                                label="Cost"
                                id="outlined-start-adornment"
                                InputProps={{
                                    inputProps: { min: 0, type: 'number', inputMode: 'numeric', pattern: '[0-9]*' },
                                    startAdornment: <InputAdornment position="start">
                                        <Select
                                            id="currency"
                                            name="currency"
                                            value={currency}
                                            onChange={(e) => {
                                                setCurrency(e.target.value)
                                            }}
                                            autoWidth
                                        >
                                            {CURRENCIES.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </InputAdornment>,
                                }}

                                placeholder="Cost"
                                fullWidth
                                variant="outlined"
                                value={cost}
                                onChange={(e) => {
                                    setCost(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="timeFrame"
                                name="timeFrame"
                                label="Estimated time"
                                variant="outlined"
                                type="number"
                                placeholder="Time for building option"
                                InputProps={{
                                    inputProps: { min: 0, type: 'number', inputMode: 'numeric', pattern: '[0-9]*' },
                                    endAdornment: <InputAdornment position="end">
                                        <Select
                                            id="timeFrameUnit"
                                            name="timeFrameUnit"
                                            value={timeFrameUnit}
                                            onChange={handleTimeFrameChange}
                                            autoWidth
                                        >
                                            {solution[categoryValue].timeFrameUnits.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </InputAdornment>,
                                }}
                                fullWidth
                                value={timeFrame}
                                onChange={(e) => {
                                    setTime(e.target.value);
                                }}
                            />
                        </Grid>
                        {risks.map((risk, index) => {
                            return (
                                <Grid item xs={12} key={`grid${index}`}>
                                    <TextField
                                        label={`Risk ${index + 1}`}
                                        key={`risk ${index}`}
                                        id="outlined-end-adornment"
                                        className={classes.textWithAddIcon}
                                        placeholder="Enter risk"
                                        variant="outlined"
                                        multiline
                                        autoFocus
                                        row={3}
                                        value={risk}
                                        onChange={(e) => {
                                            const updatedRisks = [...risks];
                                            updatedRisks.splice(index, 1, e.target.value);
                                            setRisks(updatedRisks);
                                        }}
                                        name="risk"
                                    />
                                    <IconButton className={classes.removeIcon} onClick={() => {
                                        const newRisk = risks.filter(currentRisk => currentRisk !== risk);
                                        setRisks(newRisk);
                                    }}>
                                        <HighlightOffIcon />
                                    </IconButton>
                                </Grid>
                            );
                        })}
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                disabled={risks.length >= risksLimit}
                                variant="contained"
                                color="secondary"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setRisks([...risks, '']);
                                }}>
                                Risk
                    </Button>
                    Maximum {risksLimit} are allowed
                </Grid>


                        <Grid item xs={12}>
                            <TextField
                                id="additionalInfo"
                                name="additionalInfo"
                                label="Additional information"
                                variant="outlined"
                                placeholder="Additional information"
                                multiline
                                rows={3}
                                value={additionalInfo}
                                fullWidth
                                onChange={(e) => {
                                    setAdditionalInfo(e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Button className={classes.button} variant="outlined" fullWidth onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button className={classes.button} startIcon={<SaveIcon />}
                                variant="contained" fullWidth color="primary" onClick={handleSave}
                                disabled={title === '' || cost === '' || timeFrame === '' || isDuplicateOption()}>
                                {data ? 'Update' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

CreateUpdateSolutionDialog.propTypes = {
    deliveryOptions: PropTypes.array,
};

const mapStateToProps = (state) => {
    const currentProjectData = getCurrentProjectById(state.ceci.projects, state.ceci.global.currentProjectId)
    return {
        deliveryOptions: idx(currentProjectData, _ => _.deliveryOptions) || [],
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateUpdateSolutionDialog);