import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { fetchNeedTitle, fetchAverageEvaluatorRatingOfNeed, fetchRatingByOptionIdAndFeatureId, fetchRatingByOptionIdAndOutcomeId } from './../../../../utility/CalculationUtility';
import { ratingColorMapping } from './../../../../constants/summary';

function FeaturesAndOutcomes({ optionRatings, deliveryOptions, customerNeedRatings, customerNeeds, hasOutcomes, stakeHolderNeedRatings, stakeHolderNeeds }) {
    const borderColor = '#90e5fc'
    const commonRateWidth = (60 / (deliveryOptions.length + 1)) + '%';
    const labelWidth = '40%';
    const ratingColor = (num) => ratingColorMapping[num];

    const styles = StyleSheet.create({
        tableContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 24,
            borderWidth: 1,
            borderColor: '#bff0fd',
        },
        tableheadcontainer: {
            flexDirection: 'row',
            borderBottomColor: '#bff0fd',
            backgroundColor: '#bff0fd',
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 'auto',
            textAlign: 'center',
            fontStyle: 'bold',
            flexGrow: 1,
        },
        needHeadLabel: {
            width: labelWidth,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 8,
            paddingBottom: 8,
            fontWeight: 'bold'
        },
        needRateLabel: {
            width: commonRateWidth,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 8,
            paddingBottom: 8,
            fontWeight: 'bold'
        },
        row: {
            flexDirection: 'row',
            borderBottomColor: '#bff0fd',
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 'auto',
            fontStyle: 'bold',
        },
        need: {
            width: labelWidth,
            textAlign: 'left',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 4,
            paddingBottom: 4,
            wordBreak: 'break-word',
        },
        needRate: {
            width: commonRateWidth,
            textAlign: 'center',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 4,
            paddingBottom: 4,
        },
        optionNeedRate: {
            width: commonRateWidth,
            textAlign: 'center',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 4,
            paddingBottom: 4,
        },
        color0: {
            backgroundColor: ratingColor(0),
        },
        color1: {
            backgroundColor: ratingColor(1),
        },
        color2: {
            backgroundColor: ratingColor(2),
        },
        color3: {
            backgroundColor: ratingColor(3),
        },
        color4: {
            backgroundColor: ratingColor(4),
        },
        color5: {
            backgroundColor: ratingColor(5),
        }
    });
    return (
        <React.Fragment>
            <View style={styles.tableContainer}>
                <View style={styles.tableheadcontainer}>
                    <Text style={styles.needHeadLabel}>{'Features'}</Text>
                    <Text style={styles.needRateLabel}>{'Average Evaluator Rating'}</Text>
                    {deliveryOptions.map((option, id) => {
                        return (<Text style={styles.needRateLabel} key={`header_${id}`}>{option.title}</Text>)
                    })}
                </View>
                {customerNeedRatings.map((need, id) => {
                    return (<View style={styles.row} key={id}>
                        <Text style={styles.need}>{fetchNeedTitle(customerNeeds, need.customerNeedId)}</Text>
                        <Text style={styles.needRate}>{fetchAverageEvaluatorRatingOfNeed(need.customergroups)}</Text>
                        {deliveryOptions.map((option, ID) => {
                            const rating = fetchRatingByOptionIdAndFeatureId(need.customerNeedId, option.id, optionRatings);
                            const cssClass = 'color' + rating;
                            return (<Text style={[styles.optionNeedRate, styles[cssClass]]} key={`header_${ID}`}>{rating}</Text>)
                        })}
                    </View>)
                })}
            </View>
            {hasOutcomes &&
                <View style={styles.tableContainer}>
                    <View style={styles.tableheadcontainer}>
                        <Text style={styles.needHeadLabel}>{'Goals'}</Text>
                        <Text style={styles.needRateLabel}>{'Average Evaluator Rating'}</Text>
                        {deliveryOptions.map((option, id) => {
                            return (<Text style={styles.needRateLabel} key={`header_${id}`}>{option.title}</Text>)
                        })}
                    </View>
                    {stakeHolderNeedRatings.map((need, id) => {
                        return (<View style={styles.row} key={id}>
                            <Text style={styles.need}>{fetchNeedTitle(stakeHolderNeeds, need.stakeholderNeedId)}</Text>
                            <Text style={styles.needRate}>{fetchAverageEvaluatorRatingOfNeed(need.stakeholders)}</Text>
                            {deliveryOptions.map((option, ID) => {
                                const rating = fetchRatingByOptionIdAndOutcomeId(need.stakeholderNeedId, option.id, optionRatings);
                                const cssClass = 'color' + rating;
                                return (<Text style={[styles.optionNeedRate, styles[cssClass]]} key={`header_${ID}`}>{rating}</Text>)
                            })}
                        </View>)
                    })}
                </View>
            }
        </React.Fragment>
    );
}

export default FeaturesAndOutcomes