import React from "react";
import { Dialog, DialogContent, withStyles, makeStyles, IconButton, Typography } from "@material-ui/core";
import SamplePDF from './../static/samplePDF.jpg';
import CancelIcon from '@material-ui/icons/Cancel';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(() => ({
    responsive: {
        width: '100%',
        height: 'auto'
    },
    content:{
        border: '1px solid lightgrey',
        margin: 'inherit'
    }
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CancelIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function PDFPreview({ render }) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {render(handleClickOpen)}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose} />
                <DialogContent className={classes.content}>
                    <img src={SamplePDF} alt={'Sample PDF'} className={classes.responsive} width="600" height="800" />
                </DialogContent>
            </Dialog>
        </div>
    );
}
