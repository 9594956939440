import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CreateIdea from './createIdea';
import { Paper } from '@material-ui/core';
import Solution from './solutions';
import SummaryPage from './summary'
import CustomerNeeds from './customerNeeds';
import StakeholderNeeds from './stakeholderNeeds';
import CustomerNeedSolution from './customerNeedSolution';
import StakeholderNeedSolution from './stakeholderNeedSolution';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProjectById } from '../../reducers/utility';
import idx from 'idx';
import { useHistory } from 'react-router-dom';
import { HOME } from './../../constants/routes';

const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        border: '1px solid #c5ccd3',
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
}));

const steps = [{ 'title': 'Start Idea', 'desc': 'provide basic details of idea' },
{ 'title': 'Add customer needs', 'desc': 'add your needs' },
{ 'title': 'Add outcomes', 'desc': 'add your outcomes' },
{ 'title': 'Add solution', 'desc': 'add possible solution' },
{ 'title': 'Solution to customer needs rating', 'desc': 'rate your solution to need' },
{ 'title': 'Solution to outcome rating', 'desc': 'rate your solution to outcome' },
{ 'title': 'Summary', 'desc': 'summary of evaluation' }];

function getStepContent(step) {
    switch (step) {
        case 0:
            return <CreateIdea />;
        case 1:
            return <CustomerNeeds />;
        case 2:
            return <StakeholderNeeds />;
        case 3:
            return <Solution />;
        case 4:
            return <CustomerNeedSolution />;
        case 5:
            return <StakeholderNeedSolution />;
        default:
            return '';
    }
}

const CeciBody = ({ currentStep, isProjectCreated }) => {
    const classes = useStyles();
    const history = useHistory();
    useEffect(() => {
        if (!isProjectCreated) {
            history.push({ pathname: HOME });
        }
    }, [history, isProjectCreated])

    return (
        <React.Fragment>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    {currentStep === steps.length - 1 ? <SummaryPage /> : getStepContent(currentStep)}
                </Paper>
            </main>
        </React.Fragment >
    );
};


CeciBody.propTypes = {
    currentStep: PropTypes.number,
    isProjectCreated: PropTypes.string
};


const mapStateToProps = (state) => {
    const currentProjectData = getCurrentProjectById(state.ceci.projects, state.ceci.global.currentProjectId)
    return {
        currentStep: idx(currentProjectData, _ => _.currentStep) || 0,
        isProjectCreated: idx(currentProjectData, _ => _.id) || ''

    };
};

export default connect(mapStateToProps, null)(CeciBody);