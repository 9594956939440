import { loadStripe } from "@stripe/stripe-js";
const { REACT_APP_CLIENT_ID, REACT_APP_INITIATE_PAYMENT_URL, REACT_APP_VALIDATE_PAYMENT_URL } = process.env;

const stripePromise = loadStripe(REACT_APP_CLIENT_ID);

export const initiatePayment = async (id, title) => {
    const response = await fetch(REACT_APP_INITIATE_PAYMENT_URL, {
        method: "GET",
        headers: {
            id: id,
            title: title
        }
    });
    const session = await response.json();
    const stripe = await stripePromise;
    const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
    });
    if (result.error) {
        return Promise.reject();
    }
}

export const validatePayment = (sessionId) => {
    return fetch(REACT_APP_VALIDATE_PAYMENT_URL, {
        method: "GET",
        headers: {
            sessionId: sessionId
        }
    })
}