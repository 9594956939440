export const solution = {
    personal: {
        title: "Options",
        subtitle: "possible options for idea",
        timeFrameUnits: [
            {
                value: 'Days',
                label: 'Days',
            },
            {
                value: 'Weeks',
                label: 'Weeks',
            },
            {
                value: 'Months',
                label: 'Months',
            },
            {
                value: 'Years',
                label: 'Years',
            }
        ],
        limits: {
            risks: 3,
            options: 3,
            titleLength: 50
        }
    },
    business: {
        title: "Options",
        subtitle: "possible options for idea",
        timeFrameUnits: [
            {
                value: 'Sprints',
                label: 'Sprints',
            },
            {
                value: 'Days',
                label: 'Days',
            },
            {
                value: 'Weeks',
                label: 'Weeks',
            },
            {
                value: 'Months',
                label: 'Months',
            },
            {
                value: 'Years',
                label: 'Years',
            }
        ],
        limits: {
            risks: 3,
            options: 3,
            titleLength: 50
        }
    }
}
export const NO_SOLUTION = "Let us know the options you have to achieve your idea";

export const CURRENCIES = [{
    value: '$',
    label: '$',
},
{
    value: '£',
    label: '£',
},
{
    value: '₹',
    label: '₹',
},
{
    value: '€',
    label: '€',
},
{
    value: 'Kč',
    label: 'Kč',
},
{
    value: '¥',
    label: '¥',
}]