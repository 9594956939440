import React from 'react';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { chartUrl } from './../../../../utility/CalculationUtility';

function Summary({ deliveryOptions, pdfCharts, hasOutcomes }) {
    const borderColor = '#90e5fc'
    const commonWidth = (100 / (deliveryOptions.length + 1)) + '%';
    const styles = StyleSheet.create({
        tableContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 24,
            borderWidth: 1,
            borderColor: '#bff0fd',
        },
        tableheadcontainer: {
            flexDirection: 'row',
            borderBottomColor: '#bff0fd',
            backgroundColor: '#bff0fd',
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 'auto',
            textAlign: 'center',
            fontStyle: 'bold',
            flexGrow: 1,
        },
        tablehead: {
            width: commonWidth,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            wordBreak: 'break-word',
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 8,
            paddingBottom: 8,
            fontWeight: 'bold'
        },
        row: {
            flexDirection: 'row',
            borderBottomColor: '#bff0fd',
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 100,
            fontStyle: 'bold',
        },
        rowText: {
            width: commonWidth,
            textAlign: 'center',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            paddingLeft: 8,
        },
        rowValue: {
            flexDirection: 'row',
            borderBottomColor: '#bff0fd',
            borderBottomWidth: 1,
            alignItems: 'center',
            height: 24,
            fontStyle: 'bold',
            wordBreak: 'break-word',
        },
        rowValueText: {
            width: commonWidth,
            textAlign: 'center',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            paddingLeft: 8,
        },
        rowImage: {
            width: commonWidth,
            justifyContent: 'center',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            paddingLeft: 8,
            height: 100,
            transform: 'scale(1, 1)',
            objectFit: 'cover'
        },
    });
    return (
        <React.Fragment>
            <View style={styles.tableContainer}>
                <View style={styles.tableheadcontainer}>
                    <Text style={styles.tablehead}>{'Summary'}</Text>
                    {deliveryOptions.map((option, id) => {
                        return (<Text style={styles.tablehead} key={`header_${id}`}>{option.title}</Text>)
                    })}
                </View>
                <View style={styles.rowValue}>
                    <Text style={styles.rowText}>Cost</Text>
                    {deliveryOptions.map((option, id) => {
                        return (<Text style={styles.rowValueText} key={`rowdata_${id}`}>{option.currency} {option.cost}</Text>)
                    })}
                </View>
                <View style={styles.rowValue}>
                    <Text style={styles.rowText}>Timeframe</Text>
                    {deliveryOptions.map((option, id) => {
                        return (<Text style={styles.rowValueText} key={`rowdata_${id}`}>{option.timeFrame} {option.timeFrameUnit}</Text>)
                    })}
                </View>
                <View style={styles.row}>
                    <Text style={styles.rowText}>Features</Text>
                    {deliveryOptions.map((option, id) => {
                        const { featureChart = '' } = chartUrl(option.id, pdfCharts);
                        return (<Image style={styles.rowImage} key={`rowdata_${id}`} src={featureChart} />)
                    })}
                </View>
                {hasOutcomes &&
                    <View style={styles.row}>
                        <Text style={styles.rowText}>Goals</Text>
                        {deliveryOptions.map((option, id) => {
                            const { outcomeChart = '' } = chartUrl(option.id, pdfCharts);
                            return (<Image style={styles.rowImage} key={`rowdata_${id}`} src={outcomeChart} />)
                        })}
                    </View>
                }
            </View>
        </React.Fragment>
    );
}

export default Summary