const { REACT_APP_WEB_APP_TYPE } = process.env;

export const planType = {
    0: 'FREE',
    1: 'BASIC'
}

export const planTypeProperties = {
    0: {
        maxIdeas: 1
    },
    1: {
        maxIdeas: 3
    }
}

export const idea = {
    title: '',
    description: '',
    category: '',
    isTncSelected: false
}

export const customerNeeds = [{
    need: '',
    id: ''
}]

export const customergroups = [{
    name: '',
    description: '',
    id: ''
}]

export const customerNeedRatings = [{
    customerNeedId: '',
    customergroups: [{
        customerGroupId: '',
        rating: ''
    }]
}]

export const stakeHolderNeeds = [{
    need: '',
    id: ''
}]

export const stakeholders = [{
    name: '',
    description: '',
    id: ''
}]

export const stakeHolderNeedRatings = [{
    stakeholderNeedId: '',
    stakeholders: [{
        stakeholderId: '',
        rating: ''
    }]
}]

export const deliveryOptions = [{
    id: '',
    title: '',
    description: '',
    cost: '',
    timeFrame: '',
    timeUnit: '',
    risks: [''],
    additionalInfo: '',
}]

export const optionRating = [{
    optionId: '',
    customerNeeds: [{
        customerNeedId: '',
        rating: ''
    }],
    stakeholderNeeds: [{
        stakeholderNeedId: '',
        rating: ''
    }]
}]

export const initial = {
    planType: REACT_APP_WEB_APP_TYPE,
    projects: [],
    global: {
        isLoading: false,
        currentProjectId: ''
    }
}

export const initialUser = {
    name: '',
    emailId: '',
    saved: ''
}

export const initialPayment = {
    paymentInitiated: false,
    paymentStatus: ''
}

