export const GLOBAL_LOADER = 'GLOBAL_LOADER';

export const CREATE_USER = 'CREATE_USER';

export const CREATE_PROJECT = 'CREATE_PROJECT';

export const REMOVE_PROJECT = 'REMOVE_PROJECT';

export const CLEAR_PROJECT = 'CLEAR_PROJECT';

export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';

export const PROJECT_DOWNLOADED = 'PROJECT_DOWNLOADED';

//IDEA ACTION
export const CREATE_IDEA = 'CREATE_IDEA';

export const INCREMENT_STEP = 'INCREMENT_STEP';

export const DECREMENT_STEP = 'DECREMENT_STEP';

export const SKIP_STEP = 'SKIP_STEP';

export const ADD_SOLUTION = 'ADD_SOLUTION';

export const REMOVE_SOLUTION = 'REMOVE_SOLUTION';

export const UPDATE_SOLUTION = 'UPDATE_SOLUTION';

export const ADD_CUSTOMER_NEED = 'ADD_CUSTOMER_NEED';

export const ADD_CUSTOMER_GROUP = 'ADD_CUSTOMER_GROUP';

export const UPDATE_CUSTOMER_GROUP = 'UPDATE_CUSTOMER_GROUP';

export const REMOVE_CUSTOMER_GROUP = 'REMOVE_CUSTOMER_GROUP';

export const REMOVE_CUSTOMER_NEED = 'REMOVE_CUSTOMER_NEED';

export const UPDATE_CUSTOMER_NEED = 'UPDATE_CUSTOMER_NEED';

export const ADD_STAKEHOLDER_NEED = 'ADD_STAKEHOLDER_NEED';

export const UPDATE_STAKEHOLDER_NEED = 'UPDATE_STAKEHOLDER_NEED';

export const REMOVE_STAKEHOLDER_NEED = 'REMOVE_STAKEHOLDER_NEED';

export const ADD_STAKEHOLDER = 'ADD_STAKEHOLDER';

export const UPDATE_STAKEHOLDER = 'UPDATE_STAKEHOLDER';

export const REMOVE_STAKEHOLDER = 'REMOVE_STAKEHOLDER';

export const UPDATE_FEATURE_RATING_BY_CUSTOMER = 'UPDATE_FEATURE_RATING_BY_CUSTOMER';

export const UPDATE_OUTCOME_RATING_BY_CUSTOMER = 'UPDATE_OUTCOME_RATING_BY_CUSTOMER';

export const UPDATE_FEATURE_RATING_BY_OPTION = 'UPDATE_FEATURE_RATING_BY_OPTION';

export const UPDATE_OUTCOME_RATING_BY_OPTION = 'UPDATE_OUTCOME_RATING_BY_OPTION';

export const UPDATE_RECOMMENDATION = 'UPDATE_RECOMMENDATION';

export const INITIATE_PAYMENT = 'INITIATE_PAYMENT';

export const COMPLETE_PAYMENT = 'COMPLETE_PAYMENT';

export const UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS';

export const DECREMENT_DOWNLOAD_ALLOWED = 'DECREMENT_DOWNLOAD_ALLOWED';

export const ALLOW_MULTIPLE_DOWNLOAD = 'ALLOW_MULTIPLE_DOWNLOAD';