export const stakeholderNeeds = {
    personal: {
        title: "Goals",
        subtitle: "add and rate goals from your idea",
        evaluatorLimit: 3,
        needLimit: 5
    },
    business: {
        title: "Goals",
        subtitle: "add and rate goals from your idea",
        evaluatorLimit: 3,
        needLimit: 5
    }
}
export const RATING_LABEL = { null: 'Not at all important', 1: 'Least important', 2: 'Slightly important', 3: 'Moderately important', 4: 'Very important', 5: 'Extremely important' };
export const NO_OUTCOMES = "Click the goal button to start adding the goals required for your idea";