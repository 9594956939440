import React from 'react';
import { Grid, Button, Snackbar, Typography, IconButton, FormHelperText, Divider, Select, InputLabel, FormControl, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { stakeholderNeeds, NO_OUTCOMES, RATING_LABEL } from './../../../constants/stakeholderNeeds'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProjectById } from '../../../reducers/utility';
import idx from 'idx';
import * as actions from '../../../actions/projectAction';
import { bindActionCreators } from 'redux';
import { idea } from './../../../constants/createIdea'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import StepperHeader from '../../../components/StepperHeader';
import MuiAlert from "@material-ui/lab/Alert";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ManageStakeholderNeedDialog from './ManageStakeholderNeedDialog';
import ManageStakeholderDialog from './ManageStakeholderDialog';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import NoDataContainer from '../../../components/NoDataContainer';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '10px',
    },
    addButton: {
        padding: '10px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
        width: '100%'
    },
    card: {
        boxShadow: '0 1px 1px rgb(0 0 0 / 25%), 0 1px 1px rgb(0 0 0 / 22%)',
        marginBottom: theme.spacing(2),
        border: '1px solid lightgrey',
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        },
        '& .MuiCardContent-root:last-child': {
            paddingBottom: '0px'
        }
    },
    cardHeader: {
        wordBreak: 'break-word',
        backgroundColor: '#f3f3f3',
        padding: '10px'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    starIcon: {
        color: '#ffb400',
        position: 'absolute'
    },
    rating: {
        padding: 'inherit'
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function StakeholderNeeds({ categoryValue, stakeholders, stakeHolderNeeds, stakeHolderNeedRatings, actions }) {
    const classes = useStyles();
    const [openDropDown, setOpenDropDown] = React.useState(false);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [snackAction, setSnackAction] = React.useState('');
    const [currentEvalutor, setCurrentEvalutor] = React.useState(stakeholders[0].name);
    const [stakeholder, setStakeholder] = React.useState(stakeholders[0]);
    const [currentSelectedExpanded, setExpanded] = React.useState('');
    const [currentOutcome, setCurrentOutcome] = React.useState('');
    const [hover, setHover] = React.useState(-1);

    categoryValue = categoryValue.toLowerCase();

    const handleExpandClick = (collapseId) => {
        setExpanded(collapseId === currentSelectedExpanded ? "" : collapseId)
    };

    const handleDeleteStakeholderNeed = (stakeholderNeed) => {
        actions.deleteStakeholderNeed(stakeholderNeed);
        setSnackOpen(true);
        setSnackMessage('goal successfully deleted');
        setSnackAction('success')
    }

    const fetchStakeholderNeedById = (stakeholderNeedId) => {
        return stakeHolderNeeds.find(stakeHolderNeed => stakeHolderNeed.id === stakeholderNeedId);
    }

    const fetchStakeholderById = (stakeholderId) => {
        return stakeholders.find(stakeHolder => stakeHolder.id === stakeholderId);
    }

    const fetchRatingByStakeholderNeedAndStakeholderId = (stakeholderId, stakeholderNeedId) => {
        const stakeHolderNeedObject = stakeHolderNeedRatings.find(stakeHolderNeedRating => stakeHolderNeedRating.stakeholderNeedId === stakeholderNeedId);
        return stakeHolderNeedObject.stakeholders.find(stakeHolder => stakeHolder.stakeholderId === stakeholderId).rating;
    }

    const updateRatingByStakeholderNeedAndStakeholderId = (stakeholderId, stakeholderNeedId, rating) => {
        if (rating === 0 || rating === null) {
            setHover(-1);
        }
        actions.updateStakeholderNeedRatingByStakeholder(stakeholderNeedId, stakeholderId, rating);
    }

    const handleNext = () => {
        actions.incrementStep();
        actions.skipStep(false);
    };

    const handleSkip = () => {
        actions.incrementStep();
        actions.skipStep(true);
    };

    const handleBack = () => {
        actions.decrementStep();
    };

    const snackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <React.Fragment>
            <StepperHeader title={stakeholderNeeds[categoryValue].title} subtitle={stakeholderNeeds[categoryValue].subtitle} />

            <Snackbar open={snackOpen} autoHideDuration={2000} onClose={snackClose}>
                <Alert onClose={snackClose} severity={snackAction}>
                    {snackMessage}
                </Alert>
            </Snackbar>

            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <ManageStakeholderNeedDialog
                        onSave={() => {
                            setSnackOpen(true);
                            setSnackAction('success')
                            setSnackMessage('Goal successfully added');
                        }}
                        categoryValue={categoryValue}
                        render={(open) => (
                            <Button
                                color="primary"
                                fullWidth
                                variant="contained"
                                disabled={stakeHolderNeeds.length >= stakeholderNeeds[categoryValue].needLimit}
                                className={classes.addButton}
                                startIcon={<PostAddIcon />}
                                onClick={open}
                            >
                                Add Goals
                            </Button>
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ManageStakeholderDialog
                        onSave={() => {
                            setSnackOpen(true);
                            setSnackAction('success')
                            setSnackMessage('Evaluator successfully added');
                        }}
                        categoryValue={categoryValue}
                        render={(open) => (
                            <Button
                                color="primary"
                                fullWidth
                                disabled={stakeholders.length >= stakeholderNeeds[categoryValue].evaluatorLimit}
                                variant="contained"
                                className={classes.addButton}
                                startIcon={<PersonAddIcon />}
                                onClick={open}
                            >
                                Add Evaluator
                            </Button>
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="flex-start" justify="flex-start" alignContent="flex-start">
                <Grid item xs={7}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-controlled-open-select-label">Evaluators</InputLabel>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            name="evaluatorSelector"
                            open={openDropDown}
                            onClose={() => {
                                setOpenDropDown(false);
                            }}
                            onOpen={() => {
                                setOpenDropDown(true);
                            }}
                            value={currentEvalutor}
                            onChange={(e) => {
                                setCurrentEvalutor(e.target.value)
                                if (e.target.value !== 'All') {
                                    const currentStakeholder = stakeholders.filter(stakeHolder => stakeHolder.name === e.target.value);
                                    setStakeholder(currentStakeholder[0]);
                                }
                            }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            {stakeholders.length === 0 ? '' : stakeholders.map((stakeHolder, index) => {
                                return (
                                    <MenuItem id={`stakeholder_${index}`} key={`stakeholder_${index}`} value={stakeHolder.name} style={{ whiteSpace: 'normal' }}>
                                        {stakeHolder.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                        <FormHelperText>View other evaluator ratings</FormHelperText>
                    </FormControl>
                </Grid>
                {currentEvalutor === 'All' ? '' :
                    <Grid item xs={5}>
                        <br />
                        <ManageStakeholderDialog
                            data={stakeholder}
                            categoryValue={categoryValue}
                            onSave={() => {
                                setSnackOpen(true);
                                setSnackMessage('Evaluator updated');
                                setSnackAction('success');
                                setCurrentEvalutor('All');
                            }}
                            render={(open) => (
                                <IconButton onClick={open}>
                                    <EditIcon />
                                </IconButton>
                            )} />
                        {stakeholders.length === 1 ? '' :
                            <IconButton onClick={() => {
                                actions.deleteStakeholder(stakeholder);
                                setSnackAction('success');
                                setSnackMessage('Goal deleted successfully');
                                setSnackOpen(true);
                                setCurrentEvalutor('All');
                            }}>
                                <DeleteIcon />
                            </IconButton>
                        }
                    </Grid>
                }
            </Grid>
            {stakeHolderNeeds.length === 0 ? '' : <Typography variant="h5">Total goals added : {stakeHolderNeeds.length}</Typography>}
            <br />
            {stakeHolderNeedRatings.length === 0 ? <NoDataContainer text={NO_OUTCOMES} />
                :
                stakeHolderNeedRatings.map((stakeHolderNeedRating, index) => {
                    const stakeholderNeedObject = fetchStakeholderNeedById(stakeHolderNeedRating.stakeholderNeedId);
                    const isOpenCollapsable = currentSelectedExpanded === stakeHolderNeedRating.stakeholderNeedId;
                    return (
                        <Card className={classes.card} id={`card_${index}`} key={`card_${index}`}
                            onMouseOver={() => setCurrentOutcome(stakeHolderNeedRating.stakeholderNeedId)}
                            onMouseOut={() => setCurrentOutcome('')}
                        >
                            <CardHeader className={classes.cardHeader} id={`cardHeader_${index}`} title={stakeholderNeedObject.need}
                                action={
                                    <div>
                                        <ManageStakeholderNeedDialog
                                            data={stakeholderNeedObject}
                                            categoryValue={categoryValue}
                                            onSave={() => {
                                                setSnackOpen(true);
                                                setSnackMessage('Goal updated');
                                                setSnackAction('success')
                                            }}
                                            render={(open) => (
                                                <IconButton onClick={open}>
                                                    <EditIcon />
                                                </IconButton>
                                            )} />
                                        <IconButton onClick={() => handleDeleteStakeholderNeed(stakeholderNeedObject)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                } />
                            <Divider />
                            {currentEvalutor === 'All' ?
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <CardContent>
                                            <Typography variant="body1" component="span">
                                                {(stakeHolderNeedRating.stakeholders.reduce((a, b) => a + b['rating'], 0) / stakeHolderNeedRating.stakeholders.length).toFixed(2).replace(/\.0+$/, '')}  <StarIcon className={classes.starIcon} />
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CardActions onClick={() => { handleExpandClick(stakeHolderNeedRating.stakeholderNeedId) }}>
                                            <IconButton
                                                className={clsx(classes.expand, {
                                                    [classes.expandOpen]: isOpenCollapsable,
                                                })}
                                                aria-expanded={isOpenCollapsable}
                                                aria-label="show more"
                                            >
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        </CardActions>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                        {stakeHolderNeedRating.stakeholders.map((stakeHolder, groupIndex) => {
                                            const customer = fetchStakeholderById(stakeHolder.stakeholderId);
                                            if (customer === undefined) {
                                                return '';
                                            } else {
                                                return (
                                                    <Collapse in={isOpenCollapsable} timeout="auto" unmountOnExit key={`collapse_${groupIndex + 1}`} id={`collapse_${groupIndex + 1}`}>
                                                        <CardContent key={`cardContent_${groupIndex + 1}`} id={`cardContent_${groupIndex + 1}`}>
                                                            <CardActions key={`cardAction_${groupIndex + 1}`} id={`cardAction_${groupIndex + 1}`}>
                                                                <Grid container>
                                                                    <Grid item xs={6}>
                                                                        {customer.name}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Rating
                                                                            name={`rating_${groupIndex + 1}`}
                                                                            key={`rating_${groupIndex + 1}`}
                                                                            id={`rating_${groupIndex + 1}`}
                                                                            value={stakeHolder.rating}
                                                                            onChange={(e, newValue) => {
                                                                                updateRatingByStakeholderNeedAndStakeholderId(stakeHolder.stakeholderId, stakeHolderNeedRating.stakeholderNeedId, newValue);
                                                                            }} />
                                                                    </Grid>
                                                                </Grid>
                                                            </CardActions>
                                                            <Divider />
                                                        </CardContent>
                                                    </Collapse>
                                                )
                                            }
                                        })}
                                    </Grid>
                                </Grid> :
                                <CardActions disableSpacing key={`cardaction_${index + 1}`}>
                                    <Rating
                                        size="large"
                                        name={`stakholderRating_${index + 1}`}
                                        key={`stakholderRating_${index + 1}`}
                                        id={`stakholderRating_${index + 1}`}
                                        onChangeActive={(e, newHover) => {
                                            setHover(newHover);
                                        }}
                                        onChange={(e, newValue) => {
                                            updateRatingByStakeholderNeedAndStakeholderId(stakeholder.id, stakeHolderNeedRating.stakeholderNeedId, newValue);
                                        }}
                                        value={fetchRatingByStakeholderNeedAndStakeholderId(stakeholder.id, stakeHolderNeedRating.stakeholderNeedId)} />
                                    {currentOutcome === stakeHolderNeedRating.stakeholderNeedId && <Typography className={classes.rating} variant="h5">{RATING_LABEL[hover !== -1 ? hover : fetchRatingByStakeholderNeedAndStakeholderId(stakeholder.id, stakeHolderNeedRating.stakeholderNeedId)]}</Typography>}
                                </CardActions>
                            }
                        </Card>
                    )
                })
            }
            <br />
            {/* bottom button */}
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <Button
                        color="primary"
                        onClick={handleBack}
                        fullWidth
                        className={classes.button}
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        onClick={handleSkip}
                        fullWidth
                        disabled={stakeHolderNeeds.length !== 0}
                        className={classes.button}
                        endIcon={<SkipNextIcon />}
                    >
                        Skip
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        fullWidth
                        disabled={stakeHolderNeeds.length === 0}
                        className={classes.button}
                        endIcon={<ArrowForwardIcon />}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

StakeholderNeeds.propTypes = {
    categoryValue: PropTypes.string,
    stakeholders: PropTypes.array,
    stakeHolderNeeds: PropTypes.array,
    stakeHolderNeedRatings: PropTypes.array
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state) => {
    const currentProjectData = getCurrentProjectById(state.ceci.projects, state.ceci.global.currentProjectId)
    return {
        categoryValue: idx(currentProjectData, _ => _.idea.category) || idea.ideaCategory[0],
        stakeholders: idx(currentProjectData, _ => _.stakeholders) || [],
        stakeHolderNeeds: idx(currentProjectData, _ => _.stakeHolderNeeds) || [],
        stakeHolderNeedRatings: idx(currentProjectData, _ => _.stakeHolderNeedRatings) || []
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StakeholderNeeds);