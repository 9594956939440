import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { AppBar, Toolbar, Tooltip, Button, Dialog, Typography, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/projectAction';
import { logEvent } from './../utility/AnalyticsUtility';
import { CECI_LITE_PROJECTS_LIMIT } from '../constants/common';
import idx from 'idx';
import LimitReached from './LimitReached';
import { multipleIdeasAllowed } from './../utility/CommonUtiliy';
import { CECI } from './../constants/routes';

const useStyles = makeStyles((theme) => ({
    appBar: {
        top: 'auto',
        bottom: 0,
        backgroundColor: 'lightGrey'
    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    extendedIcon: {
        marginRight: theme.spacing(1)
    },
    dialogTitle: {
        fontSize: '24px'
    }
}));
const { REACT_APP_WEB_APP_TYPE } = process.env;

const HomeFooter = ({ canProjectCreated = true, projects, actions }) => {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [allowCreate, setAllowCreate] = React.useState(true);
    const [currentProjectId, setCurrentProjectId] = React.useState('');

    const handleClose = () => {
        setOpen(false);
    };

    const createProject = () => {
        if (!multipleIdeasAllowed()) {
            if (projects.length === 0 || projects[0].projectDownloaded) {
                if (projects.length !== 0) {
                    actions.clearProject();
                }
                createNewProject();
            } else {
                setCurrentProjectId(projects[0].id);
                setOpen(true);
            }
        } else {
            projects.length >= CECI_LITE_PROJECTS_LIMIT ? setAllowCreate(false) : createNewProject();
        }
    }

    const handleUseExisting = () => {
        setOpen(false);
        actions.setCurrentProject(currentProjectId);
        history.push({ pathname: CECI });
    }

    const handleCreateNewProject = () => {
        actions.clearProject();
        setOpen(false);
        createNewProject();
    }

    const createNewProject = () => {
        actions.createProject();
        logEvent('create_project', { web_app_type: REACT_APP_WEB_APP_TYPE });
        history.push({ pathname: CECI });
    }

    return (
        <React.Fragment>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <div onClick={createProject} className={classes.logo}>
                        <Tooltip title="Create Idea" aria-label="Create Idea">
                            <Fab color="secondary" variant="extended" aria-label="Create Idea" className={classes.fabButton}>
                                <AddIcon className={classes.extendedIcon} /> Create Idea
                            </Fab>
                        </Tooltip>
                    </ div>
                </Toolbar>
            </AppBar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography className={classes.dialogTitle} component="span">Use existing idea?</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You have already created an idea, you want to create new or use existing
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUseExisting} color="primary" variant="outlined">
                        Use Existing
                    </Button>
                    <Button onClick={handleCreateNewProject} color="primary" variant="contained" autoFocus>
                        Create New
                    </Button>
                </DialogActions>
            </Dialog>
            {!allowCreate && <LimitReached />}
        </React.Fragment>
    );
};

HomeFooter.propTypes = {
    actions: PropTypes.object,
    canProjectCreated: PropTypes.bool,
    projects: PropTypes.array
};

const mapStateToProps = (state) => {
    const projectsData = state.ceci.projects;
    return {
        projects: idx(projectsData, _ => projectsData) || []
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeFooter);