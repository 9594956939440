import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    recommendation: {
        fontSize: 14
    },
});

const Recommendation = ({ recommendation }) => (
    <View>
        <Text style={styles.recommendation}>Recommendation: {recommendation === '' ? '________________________' : recommendation}</Text>
    </View>
);

export default Recommendation