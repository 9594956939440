import Header from "./../../components/Header";
import HomeFooter from "./../../components/HomeFooter";
import Homepage from "./Homepage";

const Home = () => {
    return (
        <>
            <Header />
            <Homepage />
            <br />
            <br />
            <br />
            <br />
            <HomeFooter />
        </>
    );
};


export default Home;