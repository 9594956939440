import { initialUser } from './initialState';
import { CREATE_USER } from '../constants/actionTypes';

export default function userReducer(state = initialUser, action) {
  switch (action.type) {
    case CREATE_USER: {
      return action.payload;
    }
    default:
      return state
  }
}