import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CECI_LITE } from './../../constants/common';
import { Redirect, useHistory } from "react-router-dom";
import NoIdeas from './NoIdeas';
import { CardContent, Grid, Paper, Typography, Card, Tooltip, IconButton, CardHeader, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { manageIdeas } from './../../constants/manageIdeas'
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/projectAction';
import StepperHeader from '../../components/StepperHeader';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LaunchIcon from '@material-ui/icons/Launch';
import { makeStyles } from '@material-ui/core/styles';
import idx from 'idx';
import { CECI, HOME } from './../../constants/routes';

const { REACT_APP_WEB_APP_TYPE } = process.env;

const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        border: '1px solid #c5ccd3',
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    dateGrid: {
        paddingTop: 'inherit',
    },
    cardHeader: {
        wordBreak: 'break-word',
        padding: '10px'
    },
    card: {
        boxShadow: '0 1px 1px rgb(0 0 0 / 25%), 0 1px 1px rgb(0 0 0 / 22%)',
        border: '1px solid lightgrey',
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        },
        '& .MuiCardContent-root:last-child': {
            paddingBottom: theme.spacing(2)
        }
    },
    dialogTitle: {
        fontSize: '24px'
    }
}));

const Ideas = ({ projects, actions }) => {
    const classes = useStyles();
    const history = useHistory();
    const [ideas, setIdeas] = React.useState(projects);
    const [showDeleteWarning, setShowDeleteWarning] = React.useState(false);
    const [selectedProjectId, setSelectedProjectId] = React.useState('');

    const formatDate = (date) => {
        return date === '' ? date : new Date(date).toLocaleString('en-GB', { hour12: false });
    }

    const deleteProject = () => {
        actions.removeProject(selectedProjectId);
        const newIdeas = ideas.filter(idea => idea.id !== selectedProjectId);
        setIdeas(newIdeas);
    }

    const handleClose = () => {
        setShowDeleteWarning(false);
    };

    const selectProject = (projectId) => {
        actions.setCurrentProject(projectId);
        history.push({ pathname: CECI });
    }

    return (
        <React.Fragment>
            {REACT_APP_WEB_APP_TYPE !== CECI_LITE ?
                <Redirect to={HOME} /> :
                ideas.length === 0 ? <NoIdeas /> :
                    <React.Fragment>
                        <main className={classes.layout}>
                            <Paper className={classes.paper}>
                                <StepperHeader title={manageIdeas.title} subtitle={manageIdeas.subtitle} />
                                <Grid container justify="flex-start" alignItems="center" spacing={3}>
                                    {ideas.map((idea, index) => {
                                        return <Grid item xs={12} sm={6} md={6} lg={6} xl={6} id={`grid_${index}`} key={`grid_${index}`}>
                                            <Card className={classes.card} id={`card_${index}`} key={`card_${index}`}>
                                                <CardHeader titleTypographyProps={{ variant: 'h3' }} title={idea.idea.title} className={classes.cardHeader} action={
                                                    <div>
                                                        <Tooltip title="Open Idea" aria-label="Open Idea">
                                                            <IconButton aria-label="delete" onClick={() => selectProject(idea.id)}>
                                                                <LaunchIcon fontSize="default" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete Idea" aria-label="Delete Idea">
                                                            <IconButton aria-label="delete" onClick={() => { setShowDeleteWarning(true); setSelectedProjectId(idea.id); }}>
                                                                <HighlightOffIcon fontSize="default" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                } />
                                                <CardContent>
                                                    <Grid container alignItems="center" className={classes.dateGrid}>
                                                        <Grid item xs={6}> Created
                                                <Typography variant="h6">{formatDate(idea.created)}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>Last Updated
                                                <Typography variant="h6">{formatDate(idea.updated)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    })}
                                </Grid>
                            </Paper>
                        </main>
                    </React.Fragment>
            }
            <Dialog
                open={showDeleteWarning}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography className={classes.dialogTitle} component="span">Delete idea ?</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure, this will delete your idea and it is not recoverable!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => { setShowDeleteWarning(false); deleteProject(); }} color="primary" variant="contained" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

Ideas.propTypes = {
    actions: PropTypes.object,
    projects: PropTypes.array
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state) => {
    const projectsData = state.ceci.projects;
    return {
        projects: idx(projectsData, _ => projectsData) || []
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ideas);
