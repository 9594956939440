import * as XLSX from "xlsx";
import { fetchNeedTotalScore, fetchNeedTitle, fetchOptionTitle, fetchAverageEvaluatorRatingOfNeed, fetchRatingByOptionIdAndFeatureId, fetchRatingByOptionIdAndOutcomeId } from './../../../utility/CalculationUtility';

export const exportSummaryToExcel = (fileName, customerNeeds, stakeHolderNeeds, deliveryOptions, optionsStrength, optionRatings, customerNeedRatings, stakeHolderNeedRatings = []) => {
    var workbook = XLSX.utils.book_new();
    // Sorting arrays which will be used to iterate in order to maintain sequence.
    customerNeedRatings.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
    optionsStrength.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))

    appendDataToSheet(workbook, 'Features', generateFeatureSheetData(customerNeeds, deliveryOptions, optionsStrength, optionRatings, customerNeedRatings));
    if (stakeHolderNeedRatings.length !== 0) {
        stakeHolderNeedRatings.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        appendDataToSheet(workbook, 'Goals', generateOutcomeSheetData(stakeHolderNeeds, deliveryOptions, optionsStrength, optionRatings, stakeHolderNeedRatings));
    }
    XLSX.writeFile(workbook, fileName + '.xlsx');
};

const appendDataToSheet = (workbook, worksheet_name, ws_data) => {
    const worksheet = XLSX.utils.aoa_to_sheet(ws_data);
    XLSX.utils.book_append_sheet(workbook, worksheet, worksheet_name);
}

const generateFeatureSheetData = (customerNeeds, deliveryOptions, optionsStrength, optionRatings, customerNeedRatings) => {
    const data = [];
    const optionTitles = [];
    const optionStrengths = [];
    optionsStrength.map((option) => {
        optionTitles.push(fetchOptionTitle(deliveryOptions, option.optionId));
        optionStrengths.push(option.featureOptionStrength);
        return { optionTitles, optionStrengths };
    });

    const featuresTitle = ['Feature', 'Feature Total Score', 'Feature Average Rating', ...optionTitles];

    data.push(optionTitles, optionStrengths, [], [], featuresTitle);

    customerNeedRatings.map((need) => {
        const featureData = [];
        const needText = fetchNeedTitle(customerNeeds, need.customerNeedId);
        const averageFeatureRating = fetchAverageEvaluatorRatingOfNeed(need.customergroups);
        const optionFeatureRatings = optionsStrength.map((option) => {
            return fetchRatingByOptionIdAndFeatureId(need.customerNeedId, option.optionId, optionRatings);
        });
        featureData.push(needText, fetchNeedTotalScore(need.customergroups), averageFeatureRating, ...optionFeatureRatings);
        data.push(featureData);
        return null;
    });
    return data;
}

const generateOutcomeSheetData = (stakeHolderNeeds, deliveryOptions, optionsStrength, optionRatings, stakeHolderNeedRatings) => {
    const data = [];
    const optionTitles = [];
    const optionStrengths = [];
    optionsStrength.map((option) => {
        optionTitles.push(fetchOptionTitle(deliveryOptions, option.optionId));
        optionStrengths.push(option.outcomeOptionStrength);
        return { optionTitles, optionStrengths };
    });

    const outcomesTitle = ['Goals', 'Goal Total Score', 'Goal Average Rating', ...optionTitles];

    data.push(optionTitles, optionStrengths, [], [], outcomesTitle);

    stakeHolderNeedRatings.map((need) => {
        const featureData = [];
        const needText = fetchNeedTitle(stakeHolderNeeds, need.stakeholderNeedId);
        const averageOutcomeRating = fetchAverageEvaluatorRatingOfNeed(need.stakeholders);
        const optionOutcomeRatings = optionsStrength.map((option) => {
            return fetchRatingByOptionIdAndOutcomeId(need.stakeholderNeedId, option.optionId, optionRatings);
        });
        featureData.push(needText, fetchNeedTotalScore(need.stakeholders), averageOutcomeRating, ...optionOutcomeRatings);
        data.push(featureData);
        return null;
    });
    return data;
}