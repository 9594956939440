import Header from "./../../components/Header";
import CeciBody from "./CeciBody";

const Ceci = () => {
    return (
        <>
            <Header />
            <CeciBody />
        </>
    );
};


export default Ceci;