import React, { useState } from 'react';
import { Grid, TextField, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { idea, ideaTermsAndConditions } from './../../../constants/createIdea'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions/projectAction';
import { getCurrentProjectById } from '../../../reducers/utility';
import idx from 'idx';
import { green } from "@material-ui/core/colors";
import StepperHeader from '../../../components/StepperHeader';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '10px',
    },
}));

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        "&$checked": {
            color: green[600]
        }
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />);


function CreateIdea({ titleValue = '', descriptionValue = '', categoryValue = idea.ideaCategory[0],
    isTncSelectedValue = false, customergroups = [], actions }) {
    const [title, setTitle] = useState(titleValue);
    const [description, setDescription] = useState(descriptionValue);
    const [category, setIdeaCategory] = useState(categoryValue);
    const [isTncSelected, setIsTncSelected] = useState(isTncSelectedValue);

    const onChangeInput = (event) => {
        switch (event.target.name) {
            case 'title':
                setTitle(event.target.value);
                break;
            case 'description':
                setDescription(event.target.value);
                break;
            default:
                break;
        }
    }
    const classes = useStyles();

    const handleNext = () => {
        actions.createIdea({ title, description, category, isTncSelected });
        if (customergroups.length === 0) {
            actions.addCustomerGroup({ name: 'You', description: 'default evaluator', influence: 1 });
        }
        actions.incrementStep();
    };

    return (
        <React.Fragment>
            <StepperHeader title={idea.title} subtitle={idea.subtitle} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField name="title"
                        value={title} onChange={onChangeInput}
                        required id="outlined-basic" label="Title"
                        inputProps={{ maxLength: idea.ideaCharLimit }}
                        helperText={`Total characters ${title.length}/${idea.ideaCharLimit}`}
                        autoFocus fullWidth variant="outlined" placeholder="Give your Idea a name" />
                </Grid>
                <Grid item xs={12}>
                    <TextField name="description" onChange={onChangeInput}
                        id="outlined-multiline-static" label="Description"
                        fullWidth multiline rows={4} variant="outlined"
                        placeholder="Provide some desciption of your idea" />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        value={category}
                        onChange={(event, newValue) => {
                            setIdeaCategory(newValue);
                        }}
                        id="ideaCategory" options={idea.ideaCategory} defaultValue={idea.ideaCategory[0]} renderInput={(params) => (
                            <TextField required fullWidth {...params} label="Category" variant="outlined" />
                        )} />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <GreenCheckbox
                                checked={isTncSelected}
                                onChange={(e) => {
                                    setIsTncSelected(e.target.checked);
                                }}
                                name="isTncSelected"
                            />
                        }
                        label={
                            <div
                                dangerouslySetInnerHTML={{ __html: ideaTermsAndConditions }}
                            ></div>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        fullWidth
                        className={classes.button}
                        disabled={title === '' || !isTncSelected}
                    >
                        Start Evaluation
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

CreateIdea.propTypes = {
    actions: PropTypes.object,
    titleValue: PropTypes.string,
    descriptionValue: PropTypes.string,
    categoryValue: PropTypes.string,
    isTncSelectedValue: PropTypes.bool,
    customergroups: PropTypes.array
};


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state) => {
    const currentProjectData = getCurrentProjectById(state.ceci.projects, state.ceci.global.currentProjectId)
    return {
        titleValue: idx(currentProjectData, _ => _.idea.title) || '',
        descriptionValue: idx(currentProjectData, _ => _.idea.description) || '',
        categoryValue: idx(currentProjectData, _ => _.idea.category) || idea.ideaCategory[0],
        isTncSelectedValue: idx(currentProjectData, _ => _.idea.isTncSelected) || false,
        customergroups: idx(currentProjectData, _ => _.customergroups) || []
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateIdea);