import React from 'react';
import logo from './../static/logo.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  image: {
    transform: 'scale(1.3)'
  }
}));

const Logo = (props) => {
  const classes = useStyles();

  return (
    <img
      alt="CeCi"
      src={logo}
      height="60px"
      width="75px"
      {...props}
      className={classes.image}
    />
  );
};

export default Logo;