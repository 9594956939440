import React from 'react';
import { Grid, TextField, Button, DialogTitle, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import * as actions from '../../../actions/projectAction';
import { bindActionCreators } from 'redux';
import idx from 'idx';
import { getCurrentProjectById } from '../../../reducers/utility';
import PropTypes from 'prop-types';

function ManageEvaluatorDialog({ data, render, onSave, categoryValue, actions, customergroups }) {
    const defaultName = idx(data, _ => _.name) || '';
    const defaultDescription = idx(data, _ => _.description) || '';
    const defaultInfluence = idx(data, _ => _.influence) || 1;

    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState(defaultName);
    const [description, setDescription] = React.useState(defaultDescription);
    const [influence, setInfluence] = React.useState(defaultInfluence);

    const isDuplicateCustomer = () => {
        return customergroups.some(customer => customer.name.toUpperCase() === name.toUpperCase()) && name !== defaultName;
    }

    const handleSave = () => {
        const id = data ? data.id : '';
        data ? actions.updateCustomerGroup({ name, description, influence, id }) :
            actions.addCustomerGroup({ name, description, influence });
        onSave && onSave();
        handleClose();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
        setName(defaultName);
        setDescription(defaultDescription);
        setInfluence(defaultInfluence);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);


    return (
        <React.Fragment>
            {render(handleClickOpen)}
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} scroll="paper">
                <DialogTitle>
                    <Typography component="div" align="center">
                        <strong>{data ? "Edit" : "Add"} Evaluator</strong>
                    </Typography>
                </DialogTitle>
                <DialogContent dividers={true} ref={descriptionElementRef} tabIndex={-1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="evaluatorName"
                                name="evaluatorName"
                                label="Evaluator name"
                                fullWidth
                                variant="outlined"
                                placeholder="Enter your evaluator name"
                                autoFocus
                                error={isDuplicateCustomer()}
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="description"
                                name="description"
                                label="Description"
                                variant="outlined"
                                placeholder="Enter description"
                                multiline
                                rows={2}
                                value={description}
                                fullWidth
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        disabled={name === '' || isDuplicateCustomer()}
                        variant="contained" color="primary">
                        {data ? 'Update' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

ManageEvaluatorDialog.propTypes = {
    customergroups: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state) => {
    const currentProjectData = getCurrentProjectById(state.ceci.projects, state.ceci.global.currentProjectId)
    return {
        customergroups: idx(currentProjectData, _ => _.customergroups) || [],
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageEvaluatorDialog);