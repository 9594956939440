import './App.css';
import PropTypes from 'prop-types';
import CssBaseline from "@material-ui/core/CssBaseline";
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import Ceci from "./containers/ceci";
import theme from "./theme";
import Home from "./containers/home";
import ManageIdeas from './containers/manageIdeas';
import Payment from './containers/payment'
import { PersistGate } from 'redux-persist/integration/react';
import { HOME, CECI, MANAGE_IDEAS, PAYMENT } from './constants/routes';

const customTheme = createMuiTheme(theme);

function App({ store, persistor, history }) {
  return (
    <MuiThemeProvider theme={createMuiTheme(customTheme)}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <Router>
              <Switch>
                <Route exact path={HOME} component={Home} />
                <Route exact path={CECI} component={Ceci} />
                <Route exact path={MANAGE_IDEAS} component={ManageIdeas} />
                <Route exact path={PAYMENT} component={Payment} />
                <Route render={() => <Redirect to="/" />} />
              </Switch>
            </Router>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </MuiThemeProvider>
  );
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  persistor: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default App;
