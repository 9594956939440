import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: colors.indigo[500]
    },
    secondary: {
      main: colors.indigo[500]
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    },
    error: {
      main: colors.red[500],
    },
    success: {
      main: colors.green[500],
    },
    warning: {
      main: '#ffc071'
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "capitalize"
      }
    },
    MuiFab: {
      root: {
        textTransform: "capitalize"
      }
    },
    MuiDialog: {
      paper: {
        margin: '8px'
      }
    }
  },
  shadows,
  typography
});

export default theme;