
import * as types from '../constants/actionTypes';
import { v4 as uuidv4 } from 'uuid';

export function createProject() {
    return {
        type: types.CREATE_PROJECT,
        payload: {
            created: Date.now(),
            updated: Date.now(),
            id: uuidv4(),
        }
    };
}

export function removeProject(payload) {
    return {
        type: types.REMOVE_PROJECT,
        payload: payload
    };
}

export function clearProject() {
    return {
        type: types.CLEAR_PROJECT
    };
}

export function setCurrentProject(payload) {
    return {
        type: types.SET_CURRENT_PROJECT,
        payload: payload
    };
}

export function projectDownloaded() {
    return {
        type: types.PROJECT_DOWNLOADED
    };
}

export function createIdea(value) {
    return {
        type: types.CREATE_IDEA,
        payload: value,
    };
}

export function incrementStep() {
    return {
        type: types.INCREMENT_STEP
    };
}

export function decrementStep() {
    return {
        type: types.DECREMENT_STEP
    };
}

export function skipStep(isSkipStepped) {
    return {
        type: types.SKIP_STEP,
        payload: isSkipStepped
    };
}

export function addSolution(payload) {
    payload.id = uuidv4();
    return {
        type: types.ADD_SOLUTION,
        payload,
    };
}

export function deleteSolution(payload) {
    return {
        type: types.REMOVE_SOLUTION,
        payload,
    };
}

export function updateSolution(payload) {
    return {
        type: types.UPDATE_SOLUTION,
        payload,
    };
}

export function addCustomerNeed(payload) {
    payload.id = uuidv4();
    return {
        type: types.ADD_CUSTOMER_NEED,
        payload,
    };
}

export function updateCustomerNeed(payload) {
    return {
        type: types.UPDATE_CUSTOMER_NEED,
        payload,
    };
}

export function deleteCustomerNeed(payload) {
    return {
        type: types.REMOVE_CUSTOMER_NEED,
        payload,
    };
}

export function addCustomerGroup(payload) {
    payload.id = uuidv4();
    return {
        type: types.ADD_CUSTOMER_GROUP,
        payload,
    };
}

export function updateCustomerGroup(payload) {
    return {
        type: types.UPDATE_CUSTOMER_GROUP,
        payload,
    };
}

export function deleteCustomerGroup(payload) {
    return {
        type: types.REMOVE_CUSTOMER_GROUP,
        payload,
    };
}

export function addStakeholderNeed(payload) {
    payload.id = uuidv4();
    return {
        type: types.ADD_STAKEHOLDER_NEED,
        payload,
    };
}

export function updateStakeholderNeed(payload) {
    return {
        type: types.UPDATE_STAKEHOLDER_NEED,
        payload,
    };
}

export function deleteStakeholderNeed(payload) {
    return {
        type: types.REMOVE_STAKEHOLDER_NEED,
        payload,
    };
}

export function addStakeholder(payload) {
    payload.id = uuidv4();
    return {
        type: types.ADD_STAKEHOLDER,
        payload,
    };
}

export function updateStakeholder(payload) {
    return {
        type: types.UPDATE_STAKEHOLDER,
        payload,
    };
}

export function deleteStakeholder(payload) {
    return {
        type: types.REMOVE_STAKEHOLDER,
        payload,
    };
}

export function updateCustomerNeedRatingByCustomerGroup(customerNeedId, customerGroupId, rating) {
    return {
        type: types.UPDATE_FEATURE_RATING_BY_CUSTOMER,
        customerNeedId: customerNeedId,
        customerGroupId: customerGroupId,
        rating: rating
    };
}

export function updateStakeholderNeedRatingByStakeholder(stakeholderNeedId, stakeholderId, rating) {
    return {
        type: types.UPDATE_OUTCOME_RATING_BY_CUSTOMER,
        stakeholderNeedId: stakeholderNeedId,
        stakeholderId: stakeholderId,
        rating: rating
    };
}

export function updateRatingByOptionAndCustomerNeed(optionId, customerNeedId, rating) {
    return {
        type: types.UPDATE_FEATURE_RATING_BY_OPTION,
        optionId: optionId,
        customerNeedId: customerNeedId,
        rating: rating
    };
}

export function updateRatingByOptionAndStakeholderNeed(optionId, stakeholderNeedId, rating) {
    return {
        type: types.UPDATE_OUTCOME_RATING_BY_OPTION,
        optionId: optionId,
        stakeholderNeedId: stakeholderNeedId,
        rating: rating
    };
}

export function updateRecommendation(recommendation) {
    return {
        type: types.UPDATE_RECOMMENDATION,
        recommendation: recommendation
    }
}

export function decrementDownloadAllowed() {
    return {
        type: types.DECREMENT_DOWNLOAD_ALLOWED,
    };
}

export function allowMultipleDownloads() {
    return {
        type: types.ALLOW_MULTIPLE_DOWNLOAD
    }
}