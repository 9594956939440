import Header from "./../../components/Header";
import HomeFooter from "./../../components/HomeFooter";
import Ideas from "./Ideas";

const ManageIdeas = () => {
    return (
        <>
            <Header />
            <Ideas />
            <br />
            <br />
            <br />
            <br />
            <HomeFooter />
        </>
    );
};


export default ManageIdeas;