import { CECI_LITE, CECI_BETA } from './../constants/common';
const { REACT_APP_WEB_APP_TYPE } = process.env;

export const isLocalhost = () => {
    return Boolean(
        window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    );
}

export const isInvalidEmail = (email) => {
    return email === '' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

export const isLiteVersion = () => {
    return REACT_APP_WEB_APP_TYPE.toUpperCase() === CECI_LITE;
}

export const isRegistrationRequired = () => {
    return REACT_APP_WEB_APP_TYPE.toUpperCase() === CECI_LITE || REACT_APP_WEB_APP_TYPE.toUpperCase() === CECI_BETA;
}

export const multipleIdeasAllowed = () => {
    return REACT_APP_WEB_APP_TYPE.toUpperCase() === CECI_LITE;
}

export const showAddToHomeAlert = () => {
    return REACT_APP_WEB_APP_TYPE.toUpperCase() !== CECI_LITE;
}

export const isAppInstalled = () => {
    return navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
}

export const paymentRequired = () => {
    return REACT_APP_WEB_APP_TYPE.toUpperCase() !== CECI_LITE;
}