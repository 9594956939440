import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Backdrop, Card, CircularProgress, CardHeader, Dialog, DialogContent, DialogContentText, DialogActions, Button, CardContent, Typography, Divider } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { summary } from './../../../constants/summary';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProjectById } from '../../../reducers/utility';
import idx from 'idx';
import * as actions from '../../../actions/projectAction';
import * as paymentAction from '../../../actions/paymentAction';
import { bindActionCreators } from 'redux';
import SummaryGauge from './../../../components/SummaryGauge';
import StepperHeader from '../../../components/StepperHeader';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { calculateSolutionsStrength } from '../../../utility/CalculationUtility'
import PDFSummary from './pdf/PDFSummary';
import ApexCharts from "apexcharts";
import { logEvent } from './../../../utility/AnalyticsUtility';
import { exportSummaryToExcel } from './ExcelDownload';
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { initiatePayment } from './../../../utility/PaymentUtility';
import { SUCCESS, FAIL } from './../../../constants/payment';
import { paymentRequired, isLiteVersion } from './../../../utility/CommonUtiliy';
import PDFPreview from './../../../components/PDFPreview';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
    card: {
        boxShadow: '0 1px 1px rgb(0 0 0 / 25%), 0 1px 1px rgb(0 0 0 / 22%)',
        cursor: 'pointer',
        border: '1px solid lightgrey',
        '& .MuiCardContent-root:last-child': {
            paddingBottom: '0px'
        }
    },
    cardHeader: {
        backgroundColor: '#f3f3f3',
        wordBreak: 'break-word'
    },
    itemTitle: {
        fontSize: '17px',
        wordBreak: 'break-word'
    },
    itemHelpText: {
        color: 'grey',
        fontSize: '12px'
    },
    button: {
        padding: '10px',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    ideaTitle: {
        textAlign: 'center',
        marginBottom: theme.spacing(3),
        wordBreak: 'break-word'
    },
    pdfButton: {
        textDecoration: 'none'
    },
    loader: {
        marginLeft: '50%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    dialogActions: {
        padding: "8px 20px",
        justifyContent: "space-between"
    }
}))

const { REACT_APP_WEB_APP_TYPE } = process.env;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SummaryPage({ id, persistedRecommendation, payment, idea, deliveryOptions = [], customerNeeds, stakeHolderNeeds, optionRatings, customerNeedRatings, stakeHolderNeedRatings, downloadAllowedCounter, actions, paymentAction }) {
    const classes = useStyles();
    const [isPageLoading, setPageLoading] = React.useState(true);
    const [optionsStrength, setOptionsStrength] = React.useState([]);
    const [openPDFDialogBox, setOpenPDFDialogBox] = React.useState(false);
    const [isPaymentSuccess, setIsPaymentSuccess] = React.useState(false);
    const [pdfCharts, setPdfCharts] = React.useState([]);
    const [recommendation, setRecommendation] = React.useState(persistedRecommendation);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [snackAction, setSnackAction] = React.useState('success');
    const [paymentInitiated, setPaymentInitiated] = React.useState(false);

    const handleBack = () => {
        actions.decrementStep();
        actions.updateRecommendation(recommendation);
    };

    const snackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackOpen(false);
    };

    const logPdfDownload = () => {
        setOpenPDFDialogBox(false);
        if (paymentRequired()) {
            setIsPaymentSuccess(false);
            actions.decrementDownloadAllowed();
        }
        actions.projectDownloaded();
        logEvent('download_pdf', { web_app_type: REACT_APP_WEB_APP_TYPE });
    }

    const fetchOptionStrengthByOptionId = (optionId) => {
        return optionsStrength.find(option => option.optionId === optionId);
    }

    const payAndDownload = () => {
        actions.updateRecommendation(recommendation);
        if (paymentRequired() && downloadAllowedCounter === 0) {
            logEvent('payment_initiated', { web_app_type: REACT_APP_WEB_APP_TYPE });
            paymentAction.initiatePayment();
            setPaymentInitiated(true);
            initiatePayment(id, idea.title)
                .catch(error => {
                    setSnackOpen(true);
                    setSnackAction('error')
                    setSnackMessage('Payment failed');
                    setPaymentInitiated(false);
                    paymentAction.completePayment();
                });
        } else {
            downloadPdf();
        }
        setIsPaymentSuccess(false);
    }

    const paymentRetryButton = <Button onClick={payAndDownload} size="small">Retry</Button>;

    const exportExcel = () => {
        logEvent('download_excel', { web_app_type: REACT_APP_WEB_APP_TYPE });
        actions.updateRecommendation(recommendation);
        exportSummaryToExcel(idea.title, customerNeeds, stakeHolderNeeds, deliveryOptions, optionsStrength, optionRatings, customerNeedRatings, stakeHolderNeedRatings);
    }

    const downloadPdf = () => {
        const charts = Promise.all(deliveryOptions.map(async (option) => {
            let outcomeChart = '';
            if (stakeHolderNeedRatings.length !== 0) {
                outcomeChart = await getDataUri(`outcome_chart_${option.id}`);
            }
            const featureChart = await getDataUri(`feature_chart_${option.id}`);
            return { id: option.id, featureChart, outcomeChart }
        }))
        charts.then((chart) => {
            setPdfCharts(chart);
            setOpenPDFDialogBox(true);
        }).catch(error => {
            setOpenPDFDialogBox(false);
        })
    }

    const allowDownload = () => {
        downloadPdf();
        setIsPaymentSuccess(false);
    }

    const getDataUri = async (chartId) => {
        return await ApexCharts.exec(chartId, "dataURI").then(({ imgURI }) => {
            return imgURI;
        });
    }

    const paymentFailSnack = () => {
        setSnackOpen(true);
        setSnackAction('error')
        setSnackMessage('Payment failed');
    }

    React.useEffect(() => {
        calculateSolutionsStrength(optionRatings, customerNeedRatings, stakeHolderNeedRatings)
            .then((response) => {
                const customerNeedOptionStrength = response[0];
                const stakeholderNeedOptionStrength = response[1];
                const needOptionStrength = customerNeedOptionStrength.map((item, i) => Object.assign({}, item, stakeholderNeedOptionStrength[i]));
                setOptionsStrength(needOptionStrength);
                setPageLoading(false);
            });
        if (paymentRequired()) {
            if (payment.paymentStatus === SUCCESS) {
                setIsPaymentSuccess(true);
                paymentAction.updatePaymentStatus('');
                actions.allowMultipleDownloads();
            }
            else if (payment.paymentStatus === FAIL) {
                setIsPaymentSuccess(false);
                paymentFailSnack();
                paymentAction.updatePaymentStatus('');
            }
        }
    }, [actions, payment, paymentAction, optionRatings, customerNeedRatings, stakeHolderNeedRatings])

    return (
        <React.Fragment>
            <Snackbar open={snackOpen} autoHideDuration={5000} onClose={snackClose}>
                <Alert onClose={snackClose} severity={snackAction} action={paymentRetryButton}>
                    {snackMessage}
                </Alert>
            </Snackbar>
            <StepperHeader title={summary.title} subtitle={summary.subtitle} />
            <Typography className={classes.ideaTitle} variant="h4">{idea.title}</Typography>
            {isPageLoading ? <CircularProgress className={classes.loader} /> :
                deliveryOptions.length > 0 ?
                    <Grid container justify="flex-start" alignItems="center" spacing={3}>
                        {deliveryOptions.map((option, index) => {
                            const currentOptionStrength = fetchOptionStrengthByOptionId(option.id);
                            return (
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} id={`grid_${index}`} key={`grid_${index}`}>
                                    <Card className={classes.card} id={`card_${index}`} key={`card_${index}`}>
                                        <CardHeader className={classes.cardHeader} id={`cardHeader_${index}`} title={option.title}
                                            titleTypographyProps={{ variant: 'h5' }} action={<div></div>}></CardHeader>
                                        <Divider />
                                        <CardContent>
                                            <Grid container alignItems="center" spacing={2}>
                                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                    <Typography component="div" align="center" className={classes.itemTitle}>
                                                        {option.currency} {option.cost}
                                                    </Typography>
                                                    <Typography align="center" className={classes.itemHelpText}>
                                                        Cost
                                                    </Typography>
                                                </Grid>
                                                <Divider orientation="vertical" flexItem />
                                                <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                                    <Typography component="div" align="center" className={classes.itemTitle}>
                                                        {option.timeFrame} {option.timeFrameUnit}
                                                    </Typography>
                                                    <Typography align="center" className={classes.itemHelpText}>
                                                        Timeframe
                                                </Typography>
                                                </Grid>

                                                {stakeHolderNeedRatings.length === 0 ?
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <SummaryGauge id={`feature_chart_${option.id}`} series={currentOptionStrength.featureOptionStrength} label="Features" />
                                                    </Grid> :
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <SummaryGauge id={`feature_chart_${option.id}`} series={currentOptionStrength.featureOptionStrength} label="Features" />
                                                    </Grid>
                                                }
                                                {stakeHolderNeedRatings.length === 0 ? '' :
                                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                                        <SummaryGauge id={`outcome_chart_${option.id}`} series={currentOptionStrength.outcomeOptionStrength} label="Goals" />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid> : ''
            }
            <Grid container justify="flex-start" alignItems="center" spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        placeholder="Your recommendation"
                        id="outlined-multiline-static"
                        value={recommendation}
                        label="Recommendation" fullWidth
                        onChange={(e) => {
                            setRecommendation(e.target.value);
                        }}
                        multiline rows={2} variant="outlined" />
                </Grid>
                <Grid item xs={isLiteVersion() ? 4 : 6}>
                    <Button
                        color="primary"
                        onClick={handleBack}
                        fullWidth
                        className={classes.button}
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                </Grid>
                {isLiteVersion() &&
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={isPageLoading}
                            onClick={exportExcel}
                            startIcon={<GetAppIcon />}
                            className={classes.button}>
                            Export Excel
                        </Button>
                    </Grid>}
                <Grid item xs={isLiteVersion() ? 4 : 6}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={isPageLoading}
                        onClick={payAndDownload}
                        startIcon={<GetAppIcon />}
                        className={classes.button}>
                        Publish PDF
                        </Button>
                </Grid>
            </Grid>
            <Grid container justify="flex-end" alignItems="flex-end">
                <PDFPreview render={(open) => <Button startIcon={<InfoIcon style={{ color: 'darkorange' }} />} onClick={open}>View Sample PDF</Button>}></PDFPreview>
            </Grid>
            { isPaymentSuccess ? allowDownload() : ''}
            <Backdrop className={classes.backdrop} open={paymentInitiated}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={openPDFDialogBox}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your PDF is ready, click on Download now button to start downloading the PDF
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    {paymentRequired() && <Typography variant="body1">{`${downloadAllowedCounter} download left`}</Typography>}
                    <PDFDownloadLink
                        document={<PDFSummary
                            idea={idea}
                            pdfCharts={pdfCharts}
                            hasOutcomes={stakeHolderNeedRatings.length !== 0}
                            deliveryOptions={deliveryOptions}
                            optionRatings={optionRatings}
                            customerNeeds={customerNeeds}
                            stakeHolderNeeds={stakeHolderNeeds}
                            customerNeedRatings={customerNeedRatings}
                            stakeHolderNeedRatings={stakeHolderNeedRatings}
                            recommendation={recommendation} />}
                        fileName={idea.title}
                        className={classes.pdfButton}>
                        {({ blob, url, loading, error }) =>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={logPdfDownload}
                                disabled={isPageLoading || loading}
                                startIcon={<GetAppIcon />}
                                className={classes.button}>
                                {loading ? 'Loading...' : 'Download now'}
                            </Button>
                        }
                    </PDFDownloadLink>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}


SummaryPage.propTypes = {
    id: PropTypes.string,
    deliveryOptions: PropTypes.array,
    idea: PropTypes.object,
    optionRatings: PropTypes.array,
    customerNeeds: PropTypes.array,
    stakeHolderNeeds: PropTypes.array,
    customerNeedRatings: PropTypes.array,
    stakeHolderNeedRatings: PropTypes.array,
    payment: PropTypes.object,
    persistedRecommendation: PropTypes.string,
    downloadAllowedCounter: PropTypes.number
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
    paymentAction: bindActionCreators(paymentAction, dispatch)
});

const mapStateToProps = (state) => {
    const currentProjectData = getCurrentProjectById(state.ceci.projects, state.ceci.global.currentProjectId)
    return {
        id: idx(currentProjectData, _ => _.id) || '',
        idea: idx(currentProjectData, _ => _.idea) || {},
        persistedRecommendation: idx(currentProjectData, _ => _.recommendation) || '',
        deliveryOptions: idx(currentProjectData, _ => _.deliveryOptions) || [],
        optionRatings: idx(currentProjectData, _ => _.optionRatings) || [],
        customerNeeds: idx(currentProjectData, _ => _.customerNeeds) || [],
        customerNeedRatings: idx(currentProjectData, _ => _.customerNeedRatings) || [],
        stakeHolderNeeds: idx(currentProjectData, _ => _.stakeHolderNeeds) || [],
        stakeHolderNeedRatings: idx(currentProjectData, _ => _.stakeHolderNeedRatings) || [],
        downloadAllowedCounter: idx(currentProjectData, _ => _.downloadAllowedCounter) || 0,
        payment: state.payment || {}
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPage);