export const stakeholderNeedSolution = {
    personal: {
        title: "Goal Evaluation",
        subtitle: "rate option for required goal",
    },
    business: {
        title: "Goal Evaluation",
        subtitle: "rate option for required goal",
    }
}
export const RATING_LABEL = { null: 'Not at all helpful', 1: 'Least helpful', 2: 'Slightly helpful', 3: 'Moderately helpful', 4: 'Very helpful', 5: 'Extremely helpful' };