import React from "react";
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px'
    }
}));
const NoDataContainer = (props) => {
    const classes = useStyles();
    return (
        <Grid>
            <div className={classes.content}>
                <Typography variant="body1">
                    {props.text}
                </Typography>
            </div>
        </Grid>
    );
};


export default NoDataContainer;