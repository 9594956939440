import React from 'react';
import { Grid, TextField, Button, DialogContentText } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import * as actions from '../../../actions/projectAction';
import { bindActionCreators } from 'redux';
import idx from 'idx';
function ManageNeedDialog({ data, render, onSave, categoryValue, actions }) {
    const defaultNeed = idx(data, _ => _.need) || '';

    const [open, setOpen] = React.useState(false);
    const [need, setNeed] = React.useState(defaultNeed);

    const handleSave = () => {
        const id = data ? data.id : '';
        data ? actions.updateStakeholderNeed({ need, id }) : actions.addStakeholderNeed({ need });
        onSave && onSave();
        handleClose();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
        setNeed(defaultNeed);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <React.Fragment>
            {render(handleClickOpen)}
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} scroll="paper">
                <DialogContent dividers={true} ref={descriptionElementRef} tabIndex={-1}>
                    <DialogContentText id="alert-dialog-description">
                        Give a brief description about goal for your idea
                    </DialogContentText>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="customerNeed"
                                name="customerNeed"
                                label="Goal details"
                                variant="outlined"
                                placeholder="Enter goal details"
                                multiline
                                rows={3}
                                autoFocus
                                value={need}
                                fullWidth
                                onChange={(e) => {
                                    setNeed(e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} disabled={need === ''} variant="contained" color="primary">
                        {data ? 'Update' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

ManageNeedDialog.propTypes = {
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

export default connect(null, mapDispatchToProps)(ManageNeedDialog);