import { initialPayment } from './initialState';
import { INITIATE_PAYMENT, COMPLETE_PAYMENT, UPDATE_PAYMENT_STATUS } from '../constants/actionTypes';

export default function userReducer(state = initialPayment, action) {
    switch (action.type) {
        case INITIATE_PAYMENT: {
            return { ...state, paymentInitiated: true };
        }
        case COMPLETE_PAYMENT: {
            return { ...state, paymentInitiated: false };
        }
        case UPDATE_PAYMENT_STATUS: {
            return { ...state, paymentStatus: action.payload }
        }
        default:
            return state
    }
}