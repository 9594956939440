export const customerNeeds = {
    personal: {
        title: "Features",
        subtitle: "add and rate features that are required for your idea",
        evaluatorLimit: 3,
        needLimit: 20
    },
    business: {
        title: "Features",
        subtitle: "add and rate features that are required for your idea",
        evaluatorLimit: 3,
        needLimit: 20
    }
}
export const NO_FEATURES = "Click the feature button to start adding the features required for your idea";

export const RATING_LABEL = { null: 'Not at all important', 1: 'Least important', 2: 'Slightly important', 3: 'Moderately important', 4: 'Very important', 5: 'Extremely important' };