import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { isRegistrationRequired, paymentRequired } from './../utility/CommonUtiliy';
import projects from './projectReducer';
import users from './userReducer';
import payment from './paymentReducer';

const rootReducer = history => {
  if (isRegistrationRequired() && paymentRequired()) {
    return combineReducers({
      router: connectRouter(history),
      ceci: projects,
      user: users,
      payment: payment
    });
  } else if (isRegistrationRequired()) {
    return combineReducers({
      router: connectRouter(history),
      ceci: projects,
      user: users
    });
  } else if (paymentRequired()) {
    return combineReducers({
      router: connectRouter(history),
      ceci: projects,
      payment: payment
    });
  } else {
    return combineReducers({
      router: connectRouter(history),
      ceci: projects
    });
  }
}

export default rootReducer;