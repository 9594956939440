import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import logo from './../../../../static/logo.png';

const styles = StyleSheet.create({
    logo: {
        width: 100,
        height: 75,
        marginLeft: '5px'
    },
    cecigenerated: {
        fontSize: 10,
        textAlign: 'center',
        color: 'grey',
    },
});

const CeciLogo = () => (
    <View>
        <Text style={styles.cecigenerated} fixed>
            ~ Created by Ceci ~
        </Text>
        <Image style={styles.logo} src={logo} />
    </View>
);

export default CeciLogo