
import * as types from '../constants/actionTypes';

export function initiatePayment() {
    return {
        type: types.INITIATE_PAYMENT
    };
}

export function completePayment() {
    return {
        type: types.COMPLETE_PAYMENT
    };
}

export function updatePaymentStatus(payload) {
    return {
        type: types.UPDATE_PAYMENT_STATUS,
        payload: payload
    };
}