import React from 'react';
import { Grid, Paper, Typography, Dialog, DialogActions, IconButton, DialogTitle, DialogContent, Button, TextField, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions/userAction';
import { isInvalidEmail, isLiteVersion, isRegistrationRequired, showAddToHomeAlert, isAppInstalled } from './../../utility/CommonUtiliy';
import * as firestore from './../../utility/FirestoreUtility';
import { useHistory } from "react-router-dom";
import { MANAGE_IDEAS } from './../../constants/routes';
import MuiAlert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

const { REACT_APP_NAME } = process.env;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    gridContainer: {
        justifyContent: 'center',
        padding: theme.spacing(2),
        textAlign: 'center'
    },
    grid: {
        paddingBottom: 'inherit'
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: '24px'
    },
    spacing: {
        padding: 'inherit'
    },
    manageIdeas: {
        textDecoration: 'underline'
    },
    alert: {
        backgroundColor: '#3f51b5'
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Homepage = ({ user, actions }) => {
    const classes = useStyles();
    const history = useHistory();
    const [openAddToHome, setOpenAddToHome] = React.useState(true);
    const [openExistingProject, setOpenExistingProject] = React.useState(false);
    const [fullName, setFullName] = React.useState('');
    const [emailId, setEmailId] = React.useState('');
    const [isEmailValid, setIsEmailValid] = React.useState(true);

    React.useEffect(() => {
        if (user.name === '' && isRegistrationRequired()) {
            setOpenExistingProject(true);
        }
    }, [user]);

    const closeAddToHomeSnackBar = () => {
        setOpenAddToHome(false);
    };

    const saveUser = () => {
        actions.createUser({ name: fullName, emailId: emailId });
        setOpenExistingProject(false);
        firestore.storeUser(fullName, emailId)
            .then(data => setOpenExistingProject(false))
            .catch(reason => console.log('error while saving', reason));
    }

    const nextLine = () => {
        return <React.Fragment>
            <div className={classes.spacing}>
                <Divider />
            </div>
        </React.Fragment>
    }

    return (
        <div className={classes.root}>
            { openAddToHome && showAddToHomeAlert() && !isAppInstalled() && <Alert className={classes.alert} severity="info" action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={closeAddToHomeSnackBar}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }>
                Add CECI to Home Screen and work offline
            </Alert>
            }
            <Grid container className={classes.gridContainer}>
                <Grid item xs={10} className={classes.grid}>
                    <Typography variant="h1">{user.name !== '' && isRegistrationRequired() ? `Hello ${user.name}, ` : ''}Welcome to {REACT_APP_NAME}</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Paper className={classes.paper}>
                        You can use the {REACT_APP_NAME} app for many different scenarios in both your personal and business life
                        {nextLine()}
                        Please click on the + icon below to start evaluating. If you need more help, click on the menu in the upper right corner
                    {isLiteVersion() &&
                            <React.Fragment>
                                {nextLine()}
                                You can open your existing project by clicking <div className={classes.manageIdeas} onClick={() => history.push(MANAGE_IDEAS)}>manage ideas</div>
                            </React.Fragment>
                        }
                        {nextLine()}
                        Note: We won`t be storing any idea data
                    </Paper>
                </Grid>
            </Grid>
            <Dialog open={openExistingProject} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <Typography className={classes.dialogTitle} component="div" >Tell me about yourself</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                autoFocus
                                id="fullName"
                                name="fullName"
                                label="Full Name"
                                variant="outlined"
                                placeholder="Enter your full name"
                                value={fullName}
                                fullWidth
                                onChange={(e) => {
                                    setFullName(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="emailId"
                                name="emailId"
                                label="Email Id"
                                variant="outlined"
                                type="email"
                                error={!isEmailValid}
                                helperText={!isEmailValid ? "Please enter valid email id" : ''}
                                placeholder="Enter your emailId"
                                value={emailId}
                                inputProps={{ type: 'email' }}
                                fullWidth
                                onChange={(e) => {
                                    isInvalidEmail(e.target.value) ? setIsEmailValid(false) : setIsEmailValid(true);
                                    setEmailId(e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        fullWidth
                        disabled={fullName === '' || !isEmailValid || emailId === ''}
                        onClick={saveUser}
                        color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

Homepage.propTypes = {
    actions: PropTypes.object,
    user: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state) => {
    return {
        user: state.user || {}
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
