import React from 'react';
import { Typography, Divider, Grid, Button, IconButton, FormHelperText, Select, InputLabel, FormControl, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { customerNeeds as customerNeedsConstant, NO_FEATURES, RATING_LABEL } from './../../../constants/customerNeeds'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProjectById } from '../../../reducers/utility';
import idx from 'idx';
import * as actions from '../../../actions/projectAction';
import { bindActionCreators } from 'redux';
import { idea } from './../../../constants/createIdea'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ManageNeedDialog from './ManageNeedDialog';
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import ManageEvaluatorDialog from './ManageEvaluatorDialog';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NoDataContainer from '../../../components/NoDataContainer';
import Rating from '@material-ui/lab/Rating';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PostAddIcon from '@material-ui/icons/PostAdd';
import StarIcon from '@material-ui/icons/Star';
import StepperHeader from '../../../components/StepperHeader';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '10px',
    },
    addButton: {
        padding: '10px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
        width: '100%'
    },
    card: {
        boxShadow: '0 1px 1px rgb(0 0 0 / 25%), 0 1px 1px rgb(0 0 0 / 22%)',
        marginBottom: theme.spacing(2),
        border: '1px solid lightgrey',
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        },
        '& .MuiCardContent-root:last-child': {
            paddingBottom: '0px'
        }
    },
    cardHeader: {
        wordBreak: 'break-word',
        backgroundColor: '#f3f3f3',
        padding: '10px'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    starIcon: {
        color: '#ffb400',
        position: 'absolute'
    },
    rating: {
        padding: 'inherit'
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CustomerNeedsConatiner({ categoryValue, stakeholders, customergroups, customerNeeds, customerNeedRatings, actions }) {
    const classes = useStyles();
    const [openDropDown, setOpenDropDown] = React.useState(false);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [snackAction, setSnackAction] = React.useState('');
    const [currentEvalutor, setCurrentEvalutor] = React.useState(customergroups[0].name);
    const [customerGroup, setCustomerGroup] = React.useState(customergroups[0]);
    const [currentSelectedExpanded, setExpanded] = React.useState('');
    const [currentFeature, setCurrentFeature] = React.useState('');
    const [hover, setHover] = React.useState(-1);

    categoryValue = categoryValue.toLowerCase();

    const handleExpandClick = (collapseId) => {
        setExpanded(collapseId === currentSelectedExpanded ? "" : collapseId)
    };

    const fetchCustomerNeedById = (customerNeedId) => {
        return customerNeeds.find(customerNeed => customerNeed.id === customerNeedId);
    }

    const fetchCustomerGroupById = (customerGroupId) => {
        return customergroups.find(customergroup => customergroup.id === customerGroupId);
    }

    const fetchRatingByCustomerNeedAndCustomerId = (customerNeedId, customerGroupId) => {
        const customerNeedObject = customerNeedRatings.find(customerNeedRating => customerNeedRating.customerNeedId === customerNeedId);
        return customerNeedObject.customergroups.find(customergroup => customergroup.customerGroupId === customerGroupId).rating;
    }

    const updateRatingByCustomerNeedAndCustomerId = (customerNeedId, customerGroupId, rating) => {
        if (rating === 0 || rating === null) {
            setHover(-1);
        }
        actions.updateCustomerNeedRatingByCustomerGroup(customerNeedId, customerGroupId, rating);
    }

    const handleDeleteCustomerNeed = (customerNeed) => {
        actions.deleteCustomerNeed(customerNeed);
        setSnackOpen(true);
        setSnackMessage('Feature successfully deleted');
        setSnackAction('success')
    }

    const handleNext = () => {
        if (stakeholders.length === 0) {
            actions.addStakeholder({ name: 'You', description: 'default evaluator', influence: 1 });
        }
        actions.incrementStep();
    };

    const handleBack = () => {
        actions.decrementStep();
    };

    const snackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <React.Fragment>
            <StepperHeader title={customerNeedsConstant[categoryValue].title} subtitle={customerNeedsConstant[categoryValue].subtitle} />
            <Snackbar open={snackOpen} autoHideDuration={2000} onClose={snackClose}>
                <Alert onClose={snackClose} severity={snackAction}>
                    {snackMessage}
                </Alert>
            </Snackbar>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <ManageNeedDialog
                        onSave={() => {
                            setSnackOpen(true);
                            setSnackAction('success')
                            setSnackMessage('Feature successfully added');
                        }}
                        categoryValue={categoryValue}
                        render={(open) => (
                            <Button
                                color="primary"
                                fullWidth
                                variant="contained"
                                disabled={customerNeeds.length >= customerNeedsConstant[categoryValue].needLimit}
                                className={classes.addButton}
                                startIcon={<PostAddIcon />}
                                onClick={open}
                            >
                                Add Features
                            </Button>
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ManageEvaluatorDialog
                        onSave={() => {
                            setSnackOpen(true);
                            setSnackAction('success')
                            setSnackMessage('Evaluator successfully added');
                        }}
                        categoryValue={categoryValue}
                        render={(open) => (
                            <Button
                                color="primary"
                                fullWidth
                                disabled={customergroups.length >= customerNeedsConstant[categoryValue].evaluatorLimit}
                                variant="contained"
                                className={classes.addButton}
                                startIcon={<PersonAddIcon />}
                                onClick={open}
                            >
                                Add Evaluator
                            </Button>
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} alignItems="flex-start" justify="flex-start" alignContent="flex-start">
                <Grid item xs={7}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-controlled-open-select-label">Evaluators</InputLabel>
                        <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            name="evaluatorSelector"
                            open={openDropDown}
                            onClose={() => {
                                setOpenDropDown(false);
                            }}
                            onOpen={() => {
                                setOpenDropDown(true);
                            }}
                            value={currentEvalutor}
                            onChange={(e) => {
                                setCurrentEvalutor(e.target.value)
                                if (e.target.value !== 'All') {
                                    const currentCustomerGroup = customergroups.filter(customergroup => customergroup.name === e.target.value);
                                    setCustomerGroup(currentCustomerGroup[0]);
                                }
                            }}
                        >
                            <MenuItem value="All">All</MenuItem>
                            {customergroups.length === 0 ? '' : customergroups.map((customergroup, index) => {
                                return (
                                    <MenuItem id={`customergroup_${index}`} key={`customergroup_${index}`} value={customergroup.name} style={{ whiteSpace: 'normal' }}>
                                        {customergroup.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                        <FormHelperText>View other evaluator ratings</FormHelperText>
                    </FormControl>
                </Grid>
                {currentEvalutor === 'All' ? '' :
                    <Grid item xs={5}>
                        <br />
                        <ManageEvaluatorDialog
                            data={customerGroup}
                            categoryValue={categoryValue}
                            onSave={() => {
                                setSnackOpen(true);
                                setSnackMessage('Evaluator updated');
                                setSnackAction('success');
                                setCurrentEvalutor('All');
                            }}
                            render={(open) => (
                                <IconButton onClick={open}>
                                    <EditIcon />
                                </IconButton>
                            )} />
                        {customergroups.length === 1 ? '' :
                            <IconButton onClick={() => {
                                actions.deleteCustomerGroup(customerGroup);
                                setSnackAction('success');
                                setSnackMessage('Evaluator deleted successfully');
                                setSnackOpen(true);
                                setCurrentEvalutor('All');
                            }}>
                                <DeleteIcon />
                            </IconButton>
                        }
                    </Grid>
                }
            </Grid>
            {customerNeeds.length === 0 ? '' : <Typography variant="h5">Total features added : {customerNeeds.length}</Typography>}
            <br />
            {customerNeedRatings.length === 0 ? <NoDataContainer text={NO_FEATURES} />
                : customerNeedRatings.map((customerNeedRating, index) => {
                    const customerNeedObject = fetchCustomerNeedById(customerNeedRating.customerNeedId);
                    const isOpenCollapsable = currentSelectedExpanded === customerNeedRating.customerNeedId;
                    return (
                        <Card className={classes.card} id={`card_${index}`} key={`card_${index}`}
                            onMouseOver={() => setCurrentFeature(customerNeedRating.customerNeedId)}
                            onMouseOut={() => setCurrentFeature('')}
                        >
                            <CardHeader className={classes.cardHeader} id={`cardHeader_${index}`} title={customerNeedObject.need}
                                action={
                                    <div>
                                        <ManageNeedDialog
                                            data={customerNeedObject}
                                            categoryValue={categoryValue}
                                            onSave={() => {
                                                setSnackOpen(true);
                                                setSnackMessage('Feature updated');
                                                setSnackAction('success')
                                            }}
                                            render={(open) => (
                                                <IconButton onClick={open}>
                                                    <EditIcon />
                                                </IconButton>
                                            )} />
                                        <IconButton onClick={() => handleDeleteCustomerNeed(customerNeedObject)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                } />
                            <Divider />
                            {currentEvalutor === 'All' ?
                                <Grid container alignItems="center">
                                    <Grid item xs={6}>
                                        <CardContent>
                                            <Typography variant="body1" component="span">
                                                {(customerNeedRating.customergroups.reduce((a, b) => a + b['rating'], 0) / customerNeedRating.customergroups.length).toFixed(2).replace(/\.0+$/, '')}  <StarIcon className={classes.starIcon} />
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CardActions onClick={() => { handleExpandClick(customerNeedRating.customerNeedId) }}>
                                            <IconButton
                                                className={clsx(classes.expand, {
                                                    [classes.expandOpen]: isOpenCollapsable,
                                                })}
                                                aria-expanded={isOpenCollapsable}
                                                aria-label="show more"
                                            >
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        </CardActions>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                        {customerNeedRating.customergroups.map((customergroup, groupIndex) => {
                                            const customer = fetchCustomerGroupById(customergroup.customerGroupId);
                                            if (customer === undefined) {
                                                return '';
                                            } else {
                                                return (
                                                    <Collapse in={isOpenCollapsable} timeout="auto" unmountOnExit key={`collapse_${groupIndex + 1}`} id={`collapse_${groupIndex + 1}`}>
                                                        <CardContent key={`cardContent_${groupIndex + 1}`} id={`cardContent_${groupIndex + 1}`}>
                                                            <CardActions key={`cardAction_${groupIndex + 1}`} id={`cardAction_${groupIndex + 1}`}>
                                                                <Grid container>
                                                                    <Grid item xs={6}>
                                                                        {customer.name}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Rating
                                                                            name={`ratings_${groupIndex + 1}`}
                                                                            key={`ratings_${groupIndex + 1}`}
                                                                            id={`ratings_${groupIndex + 1}`}
                                                                            value={customergroup.rating}
                                                                            onChange={(e, newValue) => {
                                                                                updateRatingByCustomerNeedAndCustomerId(customerNeedRating.customerNeedId, customergroup.customerGroupId, newValue);
                                                                            }} />
                                                                    </Grid>
                                                                </Grid>
                                                            </CardActions>
                                                            <Divider />
                                                        </CardContent>
                                                    </Collapse>
                                                )
                                            }
                                        })}
                                    </Grid>
                                </Grid> :
                                <CardActions disableSpacing key={`cardaction_${index + 1}`}>
                                    <Rating
                                        size="large"
                                        name={`rating_${index + 1}`}
                                        key={`rating_${index + 1}`}
                                        id={`rating_${index + 1}`}
                                        onChangeActive={(e, newHover) => {
                                            setHover(newHover);
                                        }}
                                        onChange={(e, newValue) => {
                                            updateRatingByCustomerNeedAndCustomerId(customerNeedRating.customerNeedId, customerGroup.id, newValue);
                                        }}
                                        value={fetchRatingByCustomerNeedAndCustomerId(customerNeedRating.customerNeedId, customerGroup.id)}
                                    />
                                    {currentFeature === customerNeedRating.customerNeedId && <Typography className={classes.rating} variant="h5">{RATING_LABEL[hover !== -1 ? hover : fetchRatingByCustomerNeedAndCustomerId(customerNeedRating.customerNeedId, customerGroup.id)]}</Typography>}
                                </CardActions>
                            }
                        </Card>
                    )
                })}
            <br />
            {/* Buttons on bottom */}
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Button
                        color="primary"
                        onClick={handleBack}
                        fullWidth
                        className={classes.button}
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        fullWidth
                        disabled={customerNeeds.length === 0}
                        className={classes.button}
                        endIcon={<ArrowForwardIcon />}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment >
    );
}

CustomerNeedsConatiner.propTypes = {
    categoryValue: PropTypes.string,
    customergroups: PropTypes.array,
    customerNeeds: PropTypes.array,
    customerNeedRatings: PropTypes.array,
    stakeholders: PropTypes.array
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state) => {
    const currentProjectData = getCurrentProjectById(state.ceci.projects, state.ceci.global.currentProjectId)
    return {
        categoryValue: idx(currentProjectData, _ => _.idea.category) || idea.ideaCategory[0],
        customergroups: idx(currentProjectData, _ => _.customergroups) || [],
        customerNeeds: idx(currentProjectData, _ => _.customerNeeds) || [],
        customerNeedRatings: idx(currentProjectData, _ => _.customerNeedRatings) || [],
        stakeholders: idx(currentProjectData, _ => _.stakeholders) || []
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerNeedsConatiner);