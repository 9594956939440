
import React from 'react';
import * as paymentAction from '../../actions/paymentAction';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProjectById } from '../../reducers/utility';
import idx from 'idx';
import { bindActionCreators } from 'redux';
import { validatePayment } from './../../utility/PaymentUtility';
import { paymentRequired } from './../../utility/CommonUtiliy';
import { logEvent } from './../../utility/AnalyticsUtility';
import { CECI } from './../../constants/routes';
import { Redirect } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { SUCCESS, FAIL, WARNING } from './../../constants/payment';

const { REACT_APP_WEB_APP_TYPE } = process.env;

const useStyles = makeStyles(() => ({
    redirect: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '100vh'
    },
}));
const removeQueryParam = () => {
    window.history.replaceState({}, document.title, CECI);
}

function Payment({ id, payment, paymentAction }) {
    const classes = useStyles();
    const [readyForRedirect, setReadyForRedirect] = React.useState(false);

    React.useEffect(() => {
        if (paymentRequired()) {
            const paymentStatus = new URL(window.location.href).searchParams.get('status');
            if (paymentStatus === SUCCESS) {
                const sessionId = new URL(window.location.href).searchParams.get('session_id')
                removeQueryParam();
                logEvent('payment_transaction_complete', { web_app_type: REACT_APP_WEB_APP_TYPE });
                validatePayment(sessionId)
                    .then(response => response.json())
                    .then((data) => {
                        logEvent('payment_transaction_validated', { web_app_type: REACT_APP_WEB_APP_TYPE });
                        if (data.isPaymentCompleted && data.id === id && payment.paymentInitiated) {
                            paymentAction.updatePaymentStatus(SUCCESS);
                            logEvent('payment_transaction_success', { web_app_type: REACT_APP_WEB_APP_TYPE });
                        } else {
                            paymentAction.updatePaymentStatus(WARNING);
                            logEvent('payment_validation_failure', { web_app_type: REACT_APP_WEB_APP_TYPE });
                        }
                        setReadyForRedirect(true);
                    }).catch(error => {
                        paymentAction.updatePaymentStatus(SUCCESS);
                        logEvent('payment_validation_error', { web_app_type: REACT_APP_WEB_APP_TYPE });
                        setReadyForRedirect(true);
                    });
            } else if (paymentStatus === FAIL) {
                removeQueryParam();
                paymentAction.updatePaymentStatus(FAIL);
                logEvent('payment_failure', { web_app_type: REACT_APP_WEB_APP_TYPE });
                setReadyForRedirect(true);
            } else {
                setReadyForRedirect(true);
            }
            paymentAction.completePayment();
        } else {
            setReadyForRedirect(true);
        }
    }, [id, payment, paymentAction])

    return (
        readyForRedirect ? <Redirect to={CECI} /> :
            <div className={classes.redirect}>
                Redirecting to download PDF....
            </div>
    );
}


Payment.propTypes = {
    id: PropTypes.string,
    payment: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
    paymentAction: bindActionCreators(paymentAction, dispatch)
});

const mapStateToProps = (state) => {
    const currentProjectData = getCurrentProjectById(state.ceci.projects, state.ceci.global.currentProjectId)
    return {
        id: idx(currentProjectData, _ => _.id) || '',
        payment: state.payment || {}
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);