import {
  CREATE_IDEA, CREATE_PROJECT, ADD_SOLUTION, ADD_CUSTOMER_NEED, ADD_CUSTOMER_GROUP, UPDATE_CUSTOMER_GROUP,
  REMOVE_SOLUTION, UPDATE_SOLUTION, INCREMENT_STEP, DECREMENT_STEP, SKIP_STEP, REMOVE_CUSTOMER_GROUP,
  REMOVE_CUSTOMER_NEED, UPDATE_CUSTOMER_NEED, ADD_STAKEHOLDER_NEED, UPDATE_STAKEHOLDER_NEED, REMOVE_STAKEHOLDER_NEED,
  ADD_STAKEHOLDER, UPDATE_STAKEHOLDER, REMOVE_STAKEHOLDER, UPDATE_FEATURE_RATING_BY_CUSTOMER,
  UPDATE_OUTCOME_RATING_BY_CUSTOMER, UPDATE_FEATURE_RATING_BY_OPTION, UPDATE_OUTCOME_RATING_BY_OPTION,
  CLEAR_PROJECT, SET_CURRENT_PROJECT, PROJECT_DOWNLOADED, REMOVE_PROJECT, UPDATE_RECOMMENDATION,
  DECREMENT_DOWNLOAD_ALLOWED, ALLOW_MULTIPLE_DOWNLOAD
} from '../constants/actionTypes';
import { idea, initial } from './initialState';
import { pdfDownloadAllowed } from './../constants/summary';

export default function projectReducer(state = initial, action) {
  const { global: { currentProjectId } } = state
  switch (action.type) {
    case CREATE_IDEA: {
      const { title, description, category, isTncSelected } = action.payload
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, idea: { title, description, category, isTncSelected }, updated: Date.now() } : project
        )
      };
    }
    case CREATE_PROJECT: {
      const { id, created, updated } = action.payload;
      const projectData = Object.assign({}, { projects: state.projects.concat({ id, created, updated, idea, currentStep: 0, isStepSkipped: false, projectDownloaded: false }) });
      return Object.assign({}, state, {
        ...projectData,
        global: Object.assign({}, state.global, { currentProjectId: id })
      });
    }
    case REMOVE_PROJECT: {
      const projectData = state.projects.filter(project => project.id !== action.payload);
      return Object.assign({}, state, {
        projects: projectData,
        global: Object.assign({}, state.global, { currentProjectId: '' })
      });
    }
    case CLEAR_PROJECT: {
      const projectData = Object.assign({}, { projects: [] });
      return Object.assign({}, state, {
        ...projectData,
        global: Object.assign({}, state.global, { currentProjectId: '' })
      });
    }
    case SET_CURRENT_PROJECT: {
      const projectData = Object.assign({}, { projects: state.projects });
      return Object.assign({}, state, {
        ...projectData,
        global: Object.assign({}, state.global, { currentProjectId: action.payload })
      });
    }
    case PROJECT_DOWNLOADED: {
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, projectDownloaded: true } : project
        )
      };
    }
    case INCREMENT_STEP: {
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, currentStep: project.isStepSkipped && project.currentStep + 1 === 5 ? project.currentStep + 2 : project.currentStep + 1 } : project
        )
      };
    }
    case DECREMENT_STEP: {
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, currentStep: project.isStepSkipped && project.currentStep - 1 === 5 ? project.currentStep - 2 : project.currentStep - 1 } : project
        )
      };
    }
    case SKIP_STEP: {
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, isStepSkipped: action.payload } : project
        )
      };
    }
    case ADD_SOLUTION: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const newSolution = currentProject.deliveryOptions === undefined ? [action.payload] : [action.payload, ...currentProject.deliveryOptions];
      const customerNeeds = [];
      currentProject.customerNeeds.map((customerNeed) => {
        customerNeeds.push({ customerNeedId: customerNeed.id, rating: 0 });
        return customerNeeds;
      });
      const stakeholderNeeds = [];
      if (currentProject.stakeHolderNeeds !== undefined && currentProject.stakeHolderNeeds.length !== 0) {
        currentProject.stakeHolderNeeds.map((stakeHolderNeed) => {
          stakeholderNeeds.push({ stakeholderNeedId: stakeHolderNeed.id, rating: 0 });
          return stakeholderNeeds;
        });
      }
      const optionRating = { optionId: action.payload.id, customerNeeds: customerNeeds, stakeholderNeeds: stakeholderNeeds };
      const newOptionRatings = currentProject.optionRatings === undefined ? [optionRating] : [optionRating, ...currentProject.optionRatings];
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, deliveryOptions: newSolution, optionRatings: newOptionRatings, updated: Date.now() } : project
        )
      };
    }
    case REMOVE_SOLUTION: {
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? {
            ...project,
            deliveryOptions: project.deliveryOptions.filter(deliveryOption => deliveryOption.id !== action.payload.id),
            optionRatings: project.optionRatings.filter(optionRating => optionRating.optionId !== action.payload.id),
            updated: Date.now()
          } : project
        )
      };
    }
    case UPDATE_SOLUTION: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const index = currentProject.deliveryOptions.findIndex(deliveryOption => deliveryOption.id === action.payload.id);
      const updatedSolutions = [...currentProject.deliveryOptions];
      updatedSolutions.splice(index, 1, action.payload);
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, deliveryOptions: updatedSolutions, updated: Date.now() } : project
        )
      };
    }
    case ADD_CUSTOMER_NEED: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const newCustomerNeed = currentProject.customerNeeds === undefined ? [action.payload] : [action.payload, ...currentProject.customerNeeds];

      var customerGroups = [];
      currentProject.customergroups.map((customerGroup) => {
        return (
          customerGroups = [...customerGroups, { 'customerGroupId': customerGroup.id, 'rating': 3 }]
        )
      });
      const newCustomerNeedRating = { customerNeedId: action.payload.id, customergroups: customerGroups };
      const customerNeedRating = currentProject.customerNeedRatings === undefined ? [newCustomerNeedRating] : [...currentProject.customerNeedRatings, newCustomerNeedRating];

      const newOptionRatings = [];
      const currentOptionRatings = currentProject.optionRatings;
      if (currentOptionRatings !== undefined && currentOptionRatings.length !== 0) {
        currentOptionRatings.map((optionRating) => {
          let newCustomerNeedsRating = [];
          const newRating = { customerNeedId: action.payload.id, rating: 0 };
          if (optionRating.customerNeeds === undefined || optionRating.customerNeeds.length === 0) {
            newCustomerNeedsRating = [newRating];
          } else {
            newCustomerNeedsRating = [newRating, ...optionRating.customerNeeds];
          }
          newOptionRatings.push({ optionId: optionRating.optionId, customerNeeds: newCustomerNeedsRating, stakeholderNeeds: optionRating.stakeholderNeeds });
          return newOptionRatings;
        })
      }

      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ?
            { ...project, customerNeeds: newCustomerNeed, customerNeedRatings: customerNeedRating, optionRatings: newOptionRatings, updated: Date.now() } : project
        )
      };
    }
    case UPDATE_CUSTOMER_NEED: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const index = currentProject.customerNeeds.findIndex(customerNeed => customerNeed.id === action.payload.id);
      const updatedCustomerNeeds = [...currentProject.customerNeeds];
      updatedCustomerNeeds.splice(index, 1, action.payload);
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, customerNeeds: updatedCustomerNeeds, updated: Date.now() } : project
        )
      };
    }
    case REMOVE_CUSTOMER_NEED: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const newOptionRatings = [];
      const currentOptionRatings = currentProject.optionRatings;
      if (currentOptionRatings !== undefined && currentOptionRatings.length !== 0) {
        currentOptionRatings.map((optionRating) => {
          const newCustomerNeedsRating = optionRating.customerNeeds.filter(needs => needs.customerNeedId !== action.payload.id);
          newOptionRatings.push({ optionId: optionRating.optionId, customerNeeds: newCustomerNeedsRating, stakeholderNeeds: optionRating.stakeholderNeeds });
          return newOptionRatings;
        })
      }
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? {
            ...project,
            customerNeeds: project.customerNeeds.filter(customerNeed => customerNeed.id !== action.payload.id),
            customerNeedRatings: project.customerNeedRatings.filter(customerNeedRating => customerNeedRating.customerNeedId !== action.payload.id),
            optionRatings: newOptionRatings,
            updated: Date.now()
          } : project
        )
      };
    }
    case ADD_CUSTOMER_GROUP: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const newCustomerGroup = currentProject.customergroups === undefined ? [action.payload] : [action.payload, ...currentProject.customergroups];
      if (currentProject.customerNeedRatings === undefined || currentProject.customerNeedRatings.length === 0) {
        return {
          ...state,
          projects: state.projects.map(
            (project) => project.id === currentProjectId ? { ...project, customergroups: newCustomerGroup, updated: Date.now() } : project
          )
        };
      } else {
        const currentCustomerNeedRatings = currentProject.customerNeedRatings;
        const updatedCustomerNeedRating = [];
        const newCustomerNeedGroup = { 'customerGroupId': action.payload.id, 'rating': 3 };
        currentCustomerNeedRatings.map((customerNeedRating) => {
          const needRating = {
            'customerNeedId': customerNeedRating.customerNeedId
            , 'customergroups': [...customerNeedRating.customergroups, newCustomerNeedGroup]
          };
          return (updatedCustomerNeedRating.push(needRating));
        })
        return {
          ...state,
          projects: state.projects.map(
            (project) => project.id === currentProjectId ? {
              ...project,
              customergroups: newCustomerGroup,
              customerNeedRatings: updatedCustomerNeedRating,
              updated: Date.now()
            } : project
          )
        };
      }
    }
    case UPDATE_CUSTOMER_GROUP: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const index = currentProject.customergroups.findIndex(customergroup => customergroup.id === action.payload.id);
      const updatedCustomerGroups = [...currentProject.customergroups];
      updatedCustomerGroups.splice(index, 1, action.payload);
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, customergroups: updatedCustomerGroups, updated: Date.now() } : project
        )
      };
    }
    case REMOVE_CUSTOMER_GROUP: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const currentCustomerNeedRatings = currentProject.customerNeedRatings;
      if (currentProject.customerNeedRatings === undefined || currentProject.customerNeedRatings.length === 0) {
        return {
          ...state,
          projects: state.projects.map(
            (project) => project.id === currentProjectId ? {
              ...project,
              customergroups: project.customergroups.filter(customergroup => customergroup.id !== action.payload.id),
              updated: Date.now()
            } : project
          )
        };
      } else {
        const newCustomerNeedRatings = [];
        currentCustomerNeedRatings.map((currentCustomerNeedRating) => {
          const newCustomerNeedRating = {
            'customerNeedId': currentCustomerNeedRating.customerNeedId
            , 'customergroups': currentCustomerNeedRating.customergroups.filter(customergroup => customergroup.customerGroupId !== action.payload.id)
          };
          return (newCustomerNeedRatings.push(newCustomerNeedRating));
        });

        return {
          ...state,
          projects: state.projects.map(
            (project) => project.id === currentProjectId ? {
              ...project,
              customergroups: project.customergroups.filter(customergroup => customergroup.id !== action.payload.id),
              customerNeedRatings: newCustomerNeedRatings,
              updated: Date.now()
            } : project
          )
        };
      }
    }
    case ADD_STAKEHOLDER_NEED: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const newStakeHolderNeeds = currentProject.stakeHolderNeeds === undefined ? [action.payload] : [action.payload, ...currentProject.stakeHolderNeeds];

      var stakeholders = [];
      currentProject.stakeholders.map((stakeholder) => {
        return (
          stakeholders = [...stakeholders, { 'stakeholderId': stakeholder.id, 'rating': 3 }]
        )
      });
      const newStakeHolderNeedsRating = { stakeholderNeedId: action.payload.id, stakeholders: stakeholders };
      const stakeHolderNeedRating = currentProject.stakeHolderNeedRatings === undefined ? [newStakeHolderNeedsRating] : [...currentProject.stakeHolderNeedRatings, newStakeHolderNeedsRating];

      const newOptionRatings = [];
      const currentOptionRatings = currentProject.optionRatings;
      if (currentOptionRatings !== undefined && currentOptionRatings.length !== 0) {
        currentOptionRatings.map((optionRating) => {
          let newStakeholderNeedsRating = [];
          const newRating = { stakeholderNeedId: action.payload.id, rating: 0 };
          if (optionRating.stakeholderNeeds === undefined || optionRating.stakeholderNeeds.length === 0) {
            newStakeholderNeedsRating = [newRating];
          } else {
            newStakeholderNeedsRating = [newRating, ...optionRating.stakeholderNeeds];
          }
          newOptionRatings.push({ optionId: optionRating.optionId, customerNeeds: optionRating.customerNeeds, stakeholderNeeds: newStakeholderNeedsRating });
          return newOptionRatings;
        })
      }

      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ?
            { ...project, stakeHolderNeeds: newStakeHolderNeeds, stakeHolderNeedRatings: stakeHolderNeedRating, optionRatings: newOptionRatings, updated: Date.now() } : project
        )
      };
    }
    case UPDATE_STAKEHOLDER_NEED: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const index = currentProject.stakeHolderNeeds.findIndex(stakeHolderNeed => stakeHolderNeed.id === action.payload.id);
      const updatedstakeHolderNeeds = [...currentProject.stakeHolderNeeds];
      updatedstakeHolderNeeds.splice(index, 1, action.payload);
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, stakeHolderNeeds: updatedstakeHolderNeeds, updated: Date.now() } : project
        )
      };
    }
    case REMOVE_STAKEHOLDER_NEED: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const newOptionRatings = [];
      const currentOptionRatings = currentProject.optionRatings;
      if (currentOptionRatings !== undefined && currentOptionRatings.length !== 0) {
        currentOptionRatings.map((optionRating) => {
          const newStakeholderNeedsRating = optionRating.stakeholderNeeds.filter(needs => needs.stakeholderNeedId !== action.payload.id);
          newOptionRatings.push({ optionId: optionRating.optionId, customerNeeds: optionRating.customerNeeds, stakeholderNeeds: newStakeholderNeedsRating });
          return newOptionRatings;
        })
      }

      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? {
            ...project,
            stakeHolderNeeds: project.stakeHolderNeeds.filter(stakeHolderNeed => stakeHolderNeed.id !== action.payload.id),
            stakeHolderNeedRatings: project.stakeHolderNeedRatings.filter(stakeHolderNeedRating => stakeHolderNeedRating.stakeholderNeedId !== action.payload.id),
            optionRatings: newOptionRatings,
            updated: Date.now()
          } : project
        )
      };
    }
    case ADD_STAKEHOLDER: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const newStakeholder = currentProject.stakeholders === undefined ? [action.payload] : [action.payload, ...currentProject.stakeholders];
      if (currentProject.stakeHolderNeedRatings === undefined || currentProject.stakeHolderNeedRatings.length === 0) {
        return {
          ...state,
          projects: state.projects.map(
            (project) => project.id === currentProjectId ? { ...project, stakeholders: newStakeholder, updated: Date.now() } : project
          )
        };
      } else {
        const currentstakeHolderNeedRatings = currentProject.stakeHolderNeedRatings;
        const updatedstakeHolderNeedRating = [];
        const newStakeHolderNeedsGroup = { 'stakeholderId': action.payload.id, 'rating': 3 };
        currentstakeHolderNeedRatings.map((stakeHolderNeedRating) => {
          const needRating = {
            'stakeholderNeedId': stakeHolderNeedRating.stakeholderNeedId
            , 'stakeholders': [...stakeHolderNeedRating.stakeholders, newStakeHolderNeedsGroup]
          };
          return (updatedstakeHolderNeedRating.push(needRating));
        })
        return {
          ...state,
          projects: state.projects.map(
            (project) => project.id === currentProjectId ? {
              ...project,
              stakeholders: newStakeholder,
              stakeHolderNeedRatings: updatedstakeHolderNeedRating,
              updated: Date.now()
            } : project
          )
        };
      }
    }
    case UPDATE_STAKEHOLDER: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const index = currentProject.stakeholders.findIndex(stakeholder => stakeholder.id === action.payload.id);
      const updatedstakeholders = [...currentProject.stakeholders];
      updatedstakeholders.splice(index, 1, action.payload);
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, stakeholders: updatedstakeholders, updated: Date.now() } : project
        )
      };
    }
    case REMOVE_STAKEHOLDER: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const currentstakeHolderNeedRatings = currentProject.stakeHolderNeedRatings;
      if (currentProject.stakeHolderNeedRatings === undefined || currentProject.stakeHolderNeedRatings.length === 0) {
        return {
          ...state,
          projects: state.projects.map(
            (project) => project.id === currentProjectId ? {
              ...project,
              stakeholders: project.stakeholders.filter(stakeholder => stakeholder.id !== action.payload.id),
              updated: Date.now()
            } : project
          )
        };
      } else {
        const newStakeHolderNeedsRatings = [];
        currentstakeHolderNeedRatings.map((currentstakeHolderNeedRating) => {
          const newStakeHolderNeedsRating = {
            'stakeholderNeedId': currentstakeHolderNeedRating.stakeholderNeedId
            , 'stakeholders': currentstakeHolderNeedRating.stakeholders.filter(stakeholder => stakeholder.stakeholderId !== action.payload.id)
          };
          return (newStakeHolderNeedsRatings.push(newStakeHolderNeedsRating));
        });

        return {
          ...state,
          projects: state.projects.map(
            (project) => project.id === currentProjectId ? {
              ...project,
              stakeholders: project.stakeholders.filter(stakeholder => stakeholder.id !== action.payload.id),
              stakeHolderNeedRatings: newStakeHolderNeedsRatings,
              updated: Date.now()
            } : project
          )
        };
      }
    }
    case UPDATE_FEATURE_RATING_BY_CUSTOMER: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const currentCustomerNeedRatings = currentProject.customerNeedRatings;
      const newCustomerNeedRatings = [];
      const updatedCustomerGroups = []
      currentCustomerNeedRatings.map((needRating) => {
        if (needRating.customerNeedId === action.customerNeedId) {
          needRating.customergroups.map((customer) => {
            if (customer.customerGroupId === action.customerGroupId) {
              const updatedCustomer = { customerGroupId: customer.customerGroupId, rating: action.rating };
              updatedCustomerGroups.push(updatedCustomer);
            } else {
              updatedCustomerGroups.push(customer);
            }
            return updatedCustomerGroups;
          })
          const updatedCustomerNeedRating = { customerNeedId: action.customerNeedId, customergroups: updatedCustomerGroups };
          newCustomerNeedRatings.push(updatedCustomerNeedRating);
        } else {
          newCustomerNeedRatings.push(needRating);
        }
        return newCustomerNeedRatings;
      })
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, customerNeedRatings: newCustomerNeedRatings, updated: Date.now() } : project
        )
      };
    }
    case UPDATE_OUTCOME_RATING_BY_CUSTOMER: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const currentStakeHolderNeedRatings = currentProject.stakeHolderNeedRatings;
      const newStakeHolderNeedRatings = [];
      const updatedStakeholders = []
      currentStakeHolderNeedRatings.map((needRating) => {
        if (needRating.stakeholderNeedId === action.stakeholderNeedId) {
          needRating.stakeholders.map((stakeholder) => {
            if (stakeholder.stakeholderId === action.stakeholderId) {
              const updatedStakeholder = { stakeholderId: stakeholder.stakeholderId, rating: action.rating };
              updatedStakeholders.push(updatedStakeholder);
            } else {
              updatedStakeholders.push(stakeholder);
            }
            return updatedStakeholders;
          })
          const updatedStakeholderNeedRating = { stakeholderNeedId: action.stakeholderNeedId, stakeholders: updatedStakeholders };
          newStakeHolderNeedRatings.push(updatedStakeholderNeedRating);
        } else {
          newStakeHolderNeedRatings.push(needRating);
        }
        return newStakeHolderNeedRatings;
      })
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, stakeHolderNeedRatings: newStakeHolderNeedRatings, updated: Date.now() } : project
        )
      };
    }
    case UPDATE_FEATURE_RATING_BY_OPTION: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const currentOptionRatings = currentProject.optionRatings;
      const newOptionRatings = [];
      currentOptionRatings.map((optionRating) => {
        if (optionRating.optionId === action.optionId) {
          const updatedNeeds = [];
          optionRating.customerNeeds.map((customerNeed) => {
            if (customerNeed.customerNeedId === action.customerNeedId) {
              updatedNeeds.push({ customerNeedId: action.customerNeedId, rating: action.rating });
            } else {
              updatedNeeds.push(customerNeed);
            }
            return updatedNeeds;
          })
          const updatedOptionRating = { optionId: action.optionId, stakeholderNeeds: optionRating.stakeholderNeeds, customerNeeds: updatedNeeds };
          newOptionRatings.push(updatedOptionRating);
        } else {
          newOptionRatings.push(optionRating);
        }
        return newOptionRatings;
      })
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, optionRatings: newOptionRatings, updated: Date.now() } : project
        )
      };
    }
    case UPDATE_OUTCOME_RATING_BY_OPTION: {
      const currentProject = findCurrentProjectDetails(currentProjectId, state.projects);
      const currentOptionRatings = currentProject.optionRatings;
      const newOptionRatings = [];
      currentOptionRatings.map((optionRating) => {
        if (optionRating.optionId === action.optionId) {
          const updatedNeeds = [];
          optionRating.stakeholderNeeds.map((stakeholderNeed) => {
            if (stakeholderNeed.stakeholderNeedId === action.stakeholderNeedId) {
              updatedNeeds.push({ stakeholderNeedId: action.stakeholderNeedId, rating: action.rating });
            } else {
              updatedNeeds.push(stakeholderNeed);
            }
            return updatedNeeds;
          })
          const updatedOptionRating = { optionId: action.optionId, customerNeeds: optionRating.customerNeeds, stakeholderNeeds: updatedNeeds };
          newOptionRatings.push(updatedOptionRating);
        } else {
          newOptionRatings.push(optionRating);
        }
        return newOptionRatings;
      })
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, optionRatings: newOptionRatings, updated: Date.now() } : project
        )
      };
    }
    case UPDATE_RECOMMENDATION: {
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, recommendation: action.recommendation } : project
        )
      };
    }
    case DECREMENT_DOWNLOAD_ALLOWED: {
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, downloadAllowedCounter: project.downloadAllowedCounter - 1 } : project
        )
      };
    }
    case ALLOW_MULTIPLE_DOWNLOAD: {
      return {
        ...state,
        projects: state.projects.map(
          (project) => project.id === currentProjectId ? { ...project, downloadAllowedCounter: pdfDownloadAllowed } : project
        )
      };
    }
    default:
      return state;
  }
}

const findCurrentProjectDetails = (currentprojectId, projects) => {
  return projects.find(project => project.id === currentprojectId)
};