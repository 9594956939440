export const summary = {
    title: "Summary",
    subtitle: "summary of evaluation"
}

export const ratingMapping = { null: 1, '0': 1, '1': 2, '2': 3, '3': 5, '4': 8, '5': 13 }

export const ratingColorMapping = { '0': '#FF0000', '1': '#FF0000', '2': '#FFA500', '3': '#FFFF00', '4': '#90ee90', '5': '#00FF00' }

export const multiplicationFactor = 13

export const pdfDownloadAllowed = 3