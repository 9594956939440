import React from 'react';
import { Document, Page, Text, StyleSheet } from '@react-pdf/renderer';
import CeciLogo from './CeciLogo';
import IdeaDetails from './IdeaDetails';
import Recommendation from './Recommendation';
import Summary from './Summary';
import FeaturesAndOutcomes from './FeaturesAndOutcomes';

const styles = StyleSheet.create({
    body: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 40,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 15,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
});


const PDFSummary = (props) => (
    <Document title={props.idea.title}>
        <Page size="A4" style={styles.body}>
            <CeciLogo />
            <IdeaDetails title={props.idea.title} description={props.idea.description} />
            <Recommendation recommendation={props.recommendation} />
            <Summary deliveryOptions={props.deliveryOptions} pdfCharts={props.pdfCharts} hasOutcomes={props.hasOutcomes} />
            <FeaturesAndOutcomes optionRatings={props.optionRatings} deliveryOptions={props.deliveryOptions} customerNeedRatings={props.customerNeedRatings} customerNeeds={props.customerNeeds} hasOutcomes={props.hasOutcomes} stakeHolderNeedRatings={props.stakeHolderNeedRatings} stakeHolderNeeds={props.stakeHolderNeeds} />
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
            )} fixed />
        </Page>
    </Document >
);

export default PDFSummary;