import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from "@material-ui/core/Snackbar";
import CreateUpdateSolutionDialog from './CreateUpdateSolutionDialog';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Grid, IconButton, CardHeader, Divider } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { NO_SOLUTION, solution } from './../../../constants/solution';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProjectById } from '../../../reducers/utility';
import idx from 'idx';
import * as actions from '../../../actions/projectAction';
import { bindActionCreators } from 'redux';
import { idea } from './../../../constants/createIdea'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MuiAlert from "@material-ui/lab/Alert";
import NoDataContainer from '../../../components/NoDataContainer';
import StepperHeader from '../../../components/StepperHeader';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';

const useStyles = makeStyles((theme) => ({
    addCardActions: {
        display: 'flex',
        padding: '8px',
        alignItems: 'center',
        justifyContent: 'center'
    },
    addIcon: {
        '& svg': {
            fontSize: 125,
            color: 'lightBlue'
        }
    },
    card: {
        boxShadow: '0 1px 1px rgb(0 0 0 / 25%), 0 1px 1px rgb(0 0 0 / 22%)',
        border: '1px solid lightgrey',
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        },
        '& .MuiCardContent-root:last-child': {
            paddingBottom: theme.spacing(2)
        }
    },
    cardHeader: {
        wordBreak: 'break-word',
        backgroundColor: '#f3f3f3',
        padding: '10px'
    },
    itemTitle: {
        fontSize: '17px',
        wordBreak: 'break-word'
    },
    itemHelpText: {
        color: 'grey',
        fontSize: '12px'
    },
    button: {
        padding: '10px',
    },
    addButton: {
        padding: '10px',

    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Solution({ deliveryOptions, categoryValue = idea.ideaCategory[0], actions }) {
    const classes = useStyles();
    categoryValue = categoryValue.toLowerCase();

    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [snackAction, setSnackAction] = React.useState('');

    const handleDelete = (deliveryOption) => {
        actions.deleteSolution(deliveryOption);
        setSnackAction('success');
        setSnackMessage('Option deleted successfully');
        setSnackOpen(true);
    };

    const handleNext = () => {
        actions.incrementStep();
    };

    const handleBack = () => {
        actions.decrementStep();
    };

    const savedDeliveryOptions = []

    for (const [index, deliveryOption] of deliveryOptions.entries()) {
        savedDeliveryOptions.push(<Grid item xs={12} sm={12} md={12} lg={12} xl={12} id={`grid_${index}`} key={`grid_${index}`}>
            <Card className={classes.card} id={`card_${index}`} key={`card_${index}`}>
                <CardHeader className={classes.cardHeader} id={`cardHeader_${index}`} action={
                    <div>
                        <CreateUpdateSolutionDialog
                            data={deliveryOption}
                            categoryValue={categoryValue}
                            onSave={() => {
                                setSnackOpen(true);
                                setSnackMessage('Option updated');
                                setSnackAction('success')
                            }}
                            render={(open) => (
                                <IconButton onClick={open}>
                                    <EditIcon />
                                </IconButton>
                            )} />
                        <IconButton onClick={() => handleDelete(deliveryOption)}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                }
                    title={deliveryOption.title}
                ></CardHeader>
                <Divider />
                <CardContent color="primary">
                    <Grid container alignItems="center">
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Typography component="div" align="center" className={classes.itemTitle}>
                                {deliveryOption.currency} {deliveryOption.cost}
                            </Typography>
                            <Typography align="center" className={classes.itemHelpText}>
                                Cost
                            </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                            <Typography component="div" align="center" className={classes.itemTitle}>
                                {deliveryOption.timeFrame} {deliveryOption.timeFrameUnit}
                            </Typography>
                            <Typography align="center" className={classes.itemHelpText}>
                                Timeframe
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>)
    }

    const snackClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackOpen(false);
    };

    return (
        <React.Fragment>
            <StepperHeader title={solution[categoryValue].title} subtitle={solution[categoryValue].subtitle} />
            <Snackbar open={snackOpen} autoHideDuration={2000} onClose={snackClose}>
                <Alert onClose={snackClose} severity={snackAction}>
                    {snackMessage}
                </Alert>
            </Snackbar>
            <CreateUpdateSolutionDialog
                onSave={() => {
                    setSnackOpen(true);
                    setSnackAction('success')
                    setSnackMessage('Option successfully added');
                }}
                categoryValue={categoryValue}
                render={(open) => (
                    <Button
                        color="primary"
                        fullWidth
                        disabled={deliveryOptions.length >= solution[categoryValue].limits.options}
                        variant="contained"
                        className={classes.addButton}
                        startIcon={<EmojiObjectsOutlinedIcon />}
                        onClick={open}
                    >
                        Add Options
                    </Button>
                )} />

            <br />
            <br />
            {
                savedDeliveryOptions.length > 0 ?
                    <Grid container justify="flex-start" alignItems="center" spacing={3}>
                        {savedDeliveryOptions}
                    </Grid> : <NoDataContainer text={NO_SOLUTION} />
            }

            <br />
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Button
                        color="primary"
                        onClick={handleBack}
                        fullWidth
                        className={classes.button}
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={deliveryOptions.length === 0}
                        fullWidth
                        className={classes.button}
                        endIcon={<ArrowForwardIcon />}
                    >
                        Next
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment >
    );
}

Solution.propTypes = {
    categoryValue: PropTypes.string,
    deliveryOptions: PropTypes.array
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state) => {
    const currentProjectData = getCurrentProjectById(state.ceci.projects, state.ceci.global.currentProjectId)
    return {
        categoryValue: idx(currentProjectData, _ => _.idea.category) || idea.ideaCategory[0],
        deliveryOptions: idx(currentProjectData, _ => _.deliveryOptions) || [],
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Solution);