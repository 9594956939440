import React from 'react';
import {
    Grid, Button, Divider, Typography, CardActions, Dialog, DialogActions, DialogContent,
    DialogContentText, FormHelperText, Select, InputLabel, FormControl, MenuItem, Card, CardHeader
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { stakeholderNeedSolution, RATING_LABEL } from './../../../constants/stakeholderNeedSolution'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProjectById } from '../../../reducers/utility';
import idx from 'idx';
import * as actions from '../../../actions/projectAction';
import { bindActionCreators } from 'redux';
import { idea } from './../../../constants/createIdea'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AssignmentIcon from '@material-ui/icons/Assignment';
import StepperHeader from '../../../components/StepperHeader';
import Rating from '@material-ui/lab/Rating';
import { fetchRatingByOptionIdAndOutcomeId } from './../../../utility/CalculationUtility';

const useStyles = makeStyles((theme) => ({
    button: {
        padding: '10px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
        width: '100%'
    },
    card: {
        boxShadow: '0 1px 1px rgb(0 0 0 / 25%), 0 1px 1px rgb(0 0 0 / 22%)',
        marginBottom: theme.spacing(2),
        border: '1px solid lightgrey',
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        },
        '& .MuiCardContent-root:last-child': {
            paddingBottom: '0px'
        }
    },
    cardHeader: {
        wordBreak: 'break-word',
        backgroundColor: '#f3f3f3',
        padding: '10px'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    starIcon: {
        color: '#ffb400',
        position: 'absolute'
    },
    rating: {
        padding: 'inherit'
    }
}));

function StakeholderNeedSolution({ categoryValue, stakeHolderNeeds, optionRatings, deliveryOptions, actions }) {
    const classes = useStyles();
    const [selectedOption, setSelectedOption] = React.useState(deliveryOptions[0].title);
    const [currentOption, setCurrentOption] = React.useState(deliveryOptions[0]);
    const [openDropDown, setOpenDropDown] = React.useState(false);
    const [currentNeed, setNeed] = React.useState(null);
    const [hover, setHover] = React.useState(-1);
    const [open, setOpen] = React.useState(false);

    categoryValue = categoryValue.toLowerCase();

    const handleNext = () => {
        isAllOutcomesEvaluated() ? actions.incrementStep() : setOpen(true);
    };

    const handleBack = () => {
        actions.decrementStep();
    };

    const isAllOutcomesEvaluated = () => {
        for (let option of optionRatings) {
            for (let need of option.stakeholderNeeds) {
                if (need.rating === 0 || need.rating === null) {
                    return false;
                }
            }
        }
        return true;
    }

    const incrementStep = () => {
        actions.incrementStep();
    }

    const handleClose = () => {
        setOpen(false);
    };

    const updateRatingByOptionAndStakeholderNeed = (optionId, stakeholderNeedId, rating) => {
        if (rating === 0 || rating === null) {
            setHover(-1);
        }
        actions.updateRatingByOptionAndStakeholderNeed(optionId, stakeholderNeedId, rating);
    }

    const fetchRatingByOptionAndStakeholderNeed = (optionId, stakholderNeedId) => {
        return fetchRatingByOptionIdAndOutcomeId(stakholderNeedId, optionId, optionRatings);
    }

    return (
        <React.Fragment>
            <StepperHeader title={stakeholderNeedSolution[categoryValue].title} subtitle={stakeholderNeedSolution[categoryValue].subtitle} />

            <FormControl className={classes.formControl}>
                <InputLabel id="demo-controlled-open-select-label">Options</InputLabel>
                <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    name="evaluatorSelector"
                    open={openDropDown}
                    onClose={() => {
                        setOpenDropDown(false);
                    }}
                    onOpen={() => {
                        setOpenDropDown(true);
                    }}
                    value={selectedOption}
                    onChange={(e) => {
                        setSelectedOption(e.target.value)
                        const currentDeliveryOption = deliveryOptions.filter(deliveryOption => deliveryOption.title === e.target.value);
                        setCurrentOption(currentDeliveryOption[0]);
                    }}
                >
                    {deliveryOptions.length === 0 ? '' : deliveryOptions.map((deliveryOption, index) => {
                        return (
                            <MenuItem id={`deliveryOption_${index}`} key={`deliveryOption_${index}`} value={deliveryOption.title} style={{ whiteSpace: 'normal' }}>
                                {deliveryOption.title}
                            </MenuItem>
                        )
                    })}
                </Select>
                <FormHelperText>View other option ratings</FormHelperText>
            </FormControl>
            <br />
            <br />
            {stakeHolderNeeds.map((stakeHolderNeed, index) => {
                return (
                    <Card className={classes.card} id={`card_${index}`} key={`card_${index}`}
                        onMouseOver={() => setNeed(stakeHolderNeed.id)}
                        onMouseOut={() => setNeed('')}
                    >
                        <CardHeader className={classes.cardHeader} id={`cardHeader_${index}`} title={stakeHolderNeed.need} />
                        <Divider />
                        <CardActions disableSpacing key={`cardaction_${index + 1}`}>
                            <Rating
                                size="large"
                                name={`rating_${index + 1}`}
                                key={`rating_${index + 1}`}
                                id={`rating_${index + 1}`}
                                onChangeActive={(e, newHover) => {
                                    setHover(newHover);
                                }}
                                onChange={(e, newValue) => {
                                    updateRatingByOptionAndStakeholderNeed(currentOption.id, stakeHolderNeed.id, newValue);
                                }}
                                value={fetchRatingByOptionAndStakeholderNeed(currentOption.id, stakeHolderNeed.id)}
                            />
                            {currentNeed === stakeHolderNeed.id && <Typography className={classes.rating} variant="h5">{RATING_LABEL[hover !== -1 ? hover : fetchRatingByOptionAndStakeholderNeed(currentOption.id, stakeHolderNeed.id)]}</Typography>}
                        </CardActions>
                    </Card>
                )
            })}
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Button
                        color="primary"
                        onClick={handleBack}
                        fullWidth
                        className={classes.button}
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        fullWidth
                        className={classes.button}
                        startIcon={<AssignmentIcon />}
                    >
                        Summary
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We have observed that you have missed evaluating some goals, do you wish to continue or evaluate them?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined">
                        Evaluate
                    </Button>
                    <Button onClick={incrementStep} color="primary" variant="contained" autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

StakeholderNeedSolution.propTypes = {
    categoryValue: PropTypes.string,
    deliveryOptions: PropTypes.array,
    stakeHolderNeeds: PropTypes.array,
    optionRatings: PropTypes.array
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = (state) => {
    const currentProjectData = getCurrentProjectById(state.ceci.projects, state.ceci.global.currentProjectId)
    return {
        categoryValue: idx(currentProjectData, _ => _.idea.category) || idea.ideaCategory[0],
        deliveryOptions: idx(currentProjectData, _ => _.deliveryOptions) || [],
        stakeHolderNeeds: idx(currentProjectData, _ => _.stakeHolderNeeds) || [],
        optionRatings: idx(currentProjectData, _ => _.optionRatings) || []
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StakeholderNeedSolution);