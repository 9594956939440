import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import { createBrowserHistory } from "history";
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
// import { connectRouter, routerMiddleware } from 'connected-react-router';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist' // imports from redux-persist
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import createRootReducer from '../reducers';

const { REACT_APP_WEB_APP_TYPE } = process.env;

export const history = createBrowserHistory();
// const connectRouterHistory = connectRouter(history);

const persistConfig = { key: REACT_APP_WEB_APP_TYPE, storage, blacklist: ['router'], };

const persistedReducer = persistReducer(persistConfig, createRootReducer(history)) // create a persisted reducer

function configureStoreProd(initialState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk,
    reactRouterMiddleware,
  ];

  let store = createStore(
    persistedReducer,// pass the persisted reducer instead of rootReducer to createStore
    initialState,
    compose(applyMiddleware(...middlewares))
  );

  let persistor = persistStore(store);

  return { store, persistor }
}

function configureStoreDev(initialState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    // Add other middleware on this line...

    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk,
    reactRouterMiddleware,
  ];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(
    persistedReducer, // root reducer with router state
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  let persistor = persistStore(store)
  return { store, persistor };
}
const configureStore = process.env.REACT_APP_NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;