
import * as types from '../constants/actionTypes';

export function createUser(payload) {
    return {
        type: types.CREATE_USER,
        payload: {
            name: payload.name,
            emailId: payload.emailId,
            saved: Date.now()
        }
    };
}