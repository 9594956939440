import React from 'react';
import feijoa8 from './../static/feijoa8.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  image: {
    transform: 'scale(1)'
  }
}));

const Feijoa = (props) => {
  const classes = useStyles();

  return (
    <img
      alt="Feijoa8"
      src={feijoa8}
      height="60px"
      width="75px"
      {...props}
      className={classes.image}
    />
  );
};

export default Feijoa;