import { ratingMapping, multiplicationFactor } from '../constants/summary'

export const calculateSolutionsStrength = (optionRating, customerNeedRatings, stakeHolderNeedRatings) => {
    const promise1 = calculateCustomerNeedSolutionStrength(customerNeedRatings, optionRating);
    const promise2 = calculateStakeholderNeedSolutionStrength(stakeHolderNeedRatings, optionRating);
    return Promise.all([promise1, promise2]);
}

export const mapRatingValues = (num) => ratingMapping[num];

const calculateCustomerNeedSolutionStrength = (needRating, optionRating) => {
    return new Promise((resolve, reject) => {
        const sumOfIndividulNeeds = needRating.map(need => {
            const sumOfRating = need.customergroups.reduce((accumulator, currentValue) => {
                return accumulator + mapRatingValues(currentValue.rating)
            }, 0);
            return { ...need, sumOfRating }
        })


        const sumOfNeeds = sumOfIndividulNeeds.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.sumOfRating
        }, 0);

        const customerNeedImportance = sumOfIndividulNeeds.map(need => {
            return { ...need, needImportance: Math.round((need.sumOfRating / sumOfNeeds) * 100) }
        });

        const sumOfIndividulOptions = optionRating.map(option => {
            const sumOfRating = option.customerNeeds.reduce((accumulator, currentValue) => {
                const { needImportance } = customerNeedImportance.find((need) => need.customerNeedId === currentValue.customerNeedId)
                return accumulator + (mapRatingValues(currentValue.rating) * needImportance);
            }, 0);
            return { ...option, sumOfRating }
        });

        // const sumOfOptions = sumOfIndividulOptions.reduce((accumulator, currentValue) => {
        //     return accumulator + currentValue.sumOfRating
        // }, 0);

        // const optionNeedImportance = sumOfIndividulOptions.map(option => {
        //     return { ...option, optionImportance: Math.round((option.sumOfRating / sumOfOptions) * 100) }
        // });

        const sumOfCustomerNeedImpFactor = customerNeedImportance.reduce((accumulator, currentValue) => {
            return accumulator + (multiplicationFactor * currentValue.needImportance);
        }, 0);
        const optionStrength = sumOfIndividulOptions.map(option => {
            const strength = Math.round((option.sumOfRating / sumOfCustomerNeedImpFactor) * 100)
            return { optionId: option.optionId, featureOptionStrength: Number.isNaN(strength) ? 0 : strength }
        });

        return resolve(optionStrength);
    });
}


const calculateStakeholderNeedSolutionStrength = (needRating, optionRating) => {
    return new Promise((resolve, reject) => {
        const sumOfIndividulNeeds = needRating.map(need => {
            const sumOfRating = need.stakeholders.reduce((accumulator, currentValue) => {
                return accumulator + mapRatingValues(currentValue.rating)
            }, 0);
            return { ...need, sumOfRating }
        })


        const sumOfNeeds = sumOfIndividulNeeds.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.sumOfRating
        }, 0);

        const stakeholderNeedImportance = sumOfIndividulNeeds.map(need => {
            return { ...need, needImportance: Math.round((need.sumOfRating / sumOfNeeds) * 100) }
        });

        const sumOfIndividulOptions = optionRating.map(option => {
            const sumOfRating = option.stakeholderNeeds.reduce((accumulator, currentValue) => {
                const { needImportance } = stakeholderNeedImportance.find((need) => need.stakeholderNeedId === currentValue.stakeholderNeedId)
                return accumulator + (mapRatingValues(currentValue.rating) * needImportance);
            }, 0);
            return { ...option, sumOfRating }
        });

        // const sumOfOptions = sumOfIndividulOptions.reduce((accumulator, currentValue) => {
        //     return accumulator + currentValue.sumOfRating
        // }, 0);

        // const optionNeedImportance = sumOfIndividulOptions.map(option => {
        //     return { ...option, optionImportance: Math.round((option.sumOfRating / sumOfOptions) * 100) }
        // });

        const sumOfCustomerNeedImpFactor = stakeholderNeedImportance.reduce((accumulator, currentValue) => {
            return accumulator + (multiplicationFactor * currentValue.needImportance);
        }, 0);

        const optionStrength = sumOfIndividulOptions.map(option => {
            const strength = Math.round((option.sumOfRating / sumOfCustomerNeedImpFactor) * 100)
            return { optionId: option.optionId, outcomeOptionStrength: Number.isNaN(strength) ? 0 : strength }
        });

        return resolve(optionStrength);
    });
}

export const fetchNeedTitle = (needs, id) => {
    return needs.find(feature => feature.id === id).need;
}

export const fetchNeedTotalScore = (need) => {
    return need.reduce((a, b) => a + mapRatingValues(b['rating']), 0);
}

export const fetchOptionTitle = (deliveryOptions, optionId) => {
    return deliveryOptions.find(option => option.id === optionId).title;
}

export const fetchAverageEvaluatorRatingOfNeed = (need) => {
    const totalFeatureScore = need.reduce((a, b) => a + b['rating'], 0);
    return totalFeatureScore / need.length.toFixed(2).replace(/\.0+$/, '');
}

export const fetchRatingByOptionIdAndFeatureId = (customerNeedId, optionId, optionRatings) => {
    const option = optionRatings.find(optionRating => optionRating.optionId === optionId);
    return option.customerNeeds.find(need => need.customerNeedId === customerNeedId).rating;
}

export const fetchRatingByOptionIdAndOutcomeId = (stakeholderNeedId, optionId, optionRatings) => {
    const option = optionRatings.find(optionRating => optionRating.optionId === optionId);
    return option.stakeholderNeeds.find(need => need.stakeholderNeedId === stakeholderNeedId).rating;
}

export const chartUrl = (optionId, charts) => {
    return charts.find(chart => chart.id === optionId);
}