import { firestore } from './../firebase';
import { isLocalhost } from './CommonUtiliy';

const { REACT_APP_WEB_APP_TYPE, REACT_APP_VERSION, REACT_APP_BUILD_TIME, REACT_APP_USER_COLLECTION } = process.env;

export const storeUser = (name, emailId) => {
    return isLocalhost() ? Promise.resolve('Success') :
        firestore.collection(REACT_APP_USER_COLLECTION)
            .add({
                name: name,
                emailId: emailId,
                saved: new Date(),
                appType: REACT_APP_WEB_APP_TYPE,
                appVersion: REACT_APP_VERSION + '.' + REACT_APP_BUILD_TIME,
                device: navigator.userAgent
            });
}