import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StepperHeader from '../../components/StepperHeader';
import { manageIdeas, noIdeas } from './../../constants/manageIdeas'
import NoDataContainer from './../../components/NoDataContainer';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        justifyContent: 'center',
        padding: theme.spacing(2),
        textAlign: 'center'
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        border: '1px solid #c5ccd3',
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
}));

const Ideas = () => {
    const classes = useStyles();
    return (
        <main className={classes.layout}>
            <Paper className={classes.paper}>
                <StepperHeader title={manageIdeas.title} subtitle={manageIdeas.subtitle} />
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={10}>
                        <NoDataContainer text={noIdeas} />
                    </Grid>
                </Grid>
            </Paper>
        </main>
    );
};

export default Ideas;
