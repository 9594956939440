import React from "react";
import { Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    header: {
        paddingTop: theme.spacing(3),
        textAlign: 'center',
    },
    headerHelper: {
        textAlign: 'center',
        marginBottom: theme.spacing(3)
    }
}));
const StepperHeader = (props) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Typography className={classes.header} variant="h1">{props.title}</Typography>
            <Typography className={classes.headerHelper} component="div">{props.subtitle}</Typography>
            <Divider />
            <br />
        </React.Fragment>
    );
};


export default StepperHeader;