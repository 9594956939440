import React from 'react';
import { Button, Typography, DialogContentText } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { MANAGE_IDEAS } from './../constants/routes';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        fontSize: '24px'
    }
}));

function LimitReached() {
    const [open, setOpen] = React.useState(true);
    const classes = useStyles();
    const history = useHistory();

    return (
        <React.Fragment>
            <Dialog onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={open} scroll="paper">
                <DialogTitle id="alert-dialog-title">
                    <Typography className={classes.dialogTitle} component="span">Limit Reached</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You have reached the limit for the number of ideas that can be created, click on Manage ideas to review and delete old ideas
                        </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpen(false);
                        history.push(MANAGE_IDEAS);
                    }} color="primary" variant="outlined" autoFocus>
                        Manage Ideas
                        </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

export default LimitReached;